import React from 'react';

type RoundAvatarProps = {
  text: string;
  ringClassName?: string;
  sizeClassName?: string;
  textSizeClassName?: string;
  bgClassName?: string;
  bgRGBClassName?: string;
  imgSrc?: string;
};

const RoundAvatar = ({
  text,
  ringClassName = '',
  sizeClassName = 'w-12 h-12',
  textSizeClassName = 'text-xl',
  bgClassName = 'bg-red-500',
  bgRGBClassName,
  imgSrc
}: RoundAvatarProps) => {
  return (
    <div
      className={`${
        bgRGBClassName ?? bgClassName
      } flex relative justify-center items-center rounded-full text-white ${sizeClassName} ${textSizeClassName} ${ringClassName}`}
    >
      {imgSrc ? <img className="rounded-full" alt={text} src={imgSrc} /> : text.toUpperCase()}
    </div>
  );
};

export default RoundAvatar;
