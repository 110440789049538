import React from "react";
import { Item } from "../../Models/Item";
import ItemIcon, { unequipItemIconDragType } from "../Item/ItemIcon";

type HeroCardItemsProps = {
  allowDragItems?: boolean;
  showItemsTooltip?: boolean;
  items: Array<Item>;
};

const HeroCardItems = ({
  items,
  allowDragItems = false,
  showItemsTooltip = false,
}: HeroCardItemsProps) => {
  if (!items) return <></>;
  return (
    <div className="w-full flex h-10 pt-1 pl-1">
      {items.map((item, index) => (
        <div key={item.id}>
          <ItemIcon item={item} index={index} />
        </div>
      ))}
    </div>
  );
};

export default HeroCardItems;
