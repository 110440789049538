import React from 'react';
import { useQuery } from 'react-query';
import { Link, Outlet } from 'react-router-dom';
import Page from '../Basics/Page';
import UserCard from './UserCard';

const UserList = () => {
  return (
    <Page name="Users">
      {/* <div className="w-full">
        <div className="w-full">
          {status === 'success' ? (
            <>
              <div className="w-full space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                {data?.map((user) => (
                  <Link to={`/users/${user.id}`} key={user.id}>
                    <UserCard key={user.id} user={user} />
                  </Link>
                ))}
              </div>
            </>
          ) : null}
        </div>
        <div className="w-full">
          {status === 'error' ? <span>Error: {error}</span> : null}
          {isFetching ? 'Loading...' : ' '}
        </div>
      </div> */}
      <Outlet />
    </Page>
  );
};

export default UserList;
