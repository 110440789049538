import { useContext, useEffect, useState } from "react";
import useItems from "../../Hooks/useItems";
import { UserContext } from "../../Providers/UserProvider";
import { mockedItems } from "../Home/MockedData";
import ItemIcon from "../Item/ItemIcon";
import { Item } from "../../Models/Item";

const CurrencyDisplay = () => {
  const { isLoading, isAuthenticated } = useContext(UserContext);
  const {
    getQuery: { data: itemsList, isFetching: isFetchingItemsList },
  } = useItems(isAuthenticated);
  const [mockedCurrencies, setMockedCurrencies] = useState<Item[]>(mockedItems);

  useEffect(() => {
    if (!itemsList) return;

    const newMockedCurrencies: Item[] = [];
    mockedItems.forEach((mockedItem) => {
      const items = itemsList.filter(
        (item) => mockedItem.itemTypeId === item.itemTypeId
      );
      if (items.length > 0) {
        mockedItem.stack = items[0].stack;
      }
      newMockedCurrencies.push(mockedItem);
    });

    setMockedCurrencies(newMockedCurrencies);
  }, [itemsList]);

  return (
    <div className="w-full grid grid-cols-6 mt-2 mb-3 bg-gray-900/50 rounded-md p-3">
      {mockedCurrencies.map((item, index) => {
        console.log("mockedItems item.stack", item.stack, item.name);
        return (
          <ItemIcon
            key={`itemicongrid-${index}-${item ? item.id : "0"}`}
            item={item}
            index={index}
            allowModal
            disableWhenZeroStack={!item.stack}
            itemIconSize="small"
          />
        );
      })}
    </div>
  );
};

export default CurrencyDisplay;
