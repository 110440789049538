import React, { useContext } from "react";
import LoadingButton from "../Basics/LoadingButton";
import PlusOneIcon from "../SVGIcons/PlusOneIcon";
import { LoadingContext } from "../../Providers/LoadingProvider";
import { Upgrade } from ".";
import CurrenciesSpriteMap from "../Basics/CurrenciesSpriteMap";

type UpgradeCardProps = {
  upgrade: Upgrade;
};

const UpgradeCard = ({ upgrade }: UpgradeCardProps) => {
  const { isLoading } = useContext(LoadingContext);

  const handleUpgradeClick = async () => {
    await upgrade.onClick();
  };

  return (
    <>
      <div className="border border-gray-600 rounded-lg p-4 w-full bg-gray-700/50">
        <div className="flex justify-between items-center mb-2">
          <div className="text-xl font-bold text-white">{upgrade.title}</div>
          <div className="flex flex-row align-middle">
            <span className="text-gray-400 pr-1">Price: </span>
            {upgrade.cost > 0 ? (
              <div className="flex flex-row align-middle">
                <span className="text-scrds-gold pr-1">{upgrade.cost} </span>
                <div className="inline-block">
                  <CurrenciesSpriteMap currencyId={upgrade.costCurrency} />
                </div>
              </div>
            ) : (
              <span className="text-scrds-lawngreen">Free</span>
            )}
          </div>
        </div>
        <p className="text-gray-100">{upgrade.subtitle}</p>
        <p className="text-gray-400">{upgrade.description}</p>
        <LoadingButton
          className="mt-2 border-gray-600"
          onClick={() => {
            handleUpgradeClick();
          }}
          text="Buy"
          icon={<PlusOneIcon className="h-5 w-5" fill="#fff" />}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default UpgradeCard;
