import React from "react";
import { GroupWithInstance } from "../../Models/GroupWithInstance";
import { Hero } from "../../Models/Hero";
import GroupCard from "./GroupCard";
import LoadingButton from "../Basics/LoadingButton";
import GroupAddIcon from "../SVGIcons/GroupAddIcon";
import { NavLink } from "react-router-dom";
import { ROUTE_UPGRADES } from "../../Utils/routes";
import PlusOneIcon from "../SVGIcons/PlusOneIcon";

type GroupListProps = {
  groups: GroupWithInstance[] | undefined;
  heroList: Hero[];
};

const GroupList = ({ groups, heroList }: GroupListProps) => {
  return (
    <div className="w-full flex flex-col gap-y-4">
      {groups && groups.length > 0
        ? groups.map((group) => {
            return (
              <GroupCard
                heroList={heroList}
                group={group}
                key={`groupcard-${group.id}-${group.instanceStartedAt}`}
              />
            );
          })
        : null}
      <div className="w-full flex flex-row justify-around">
        <NavLink to={ROUTE_UPGRADES}>
          <LoadingButton
            icon={<PlusOneIcon className="h-5 w-5" fill="#fff" />}
            text="Get more groups"
            onClick={() => {}}
          />
        </NavLink>
      </div>
    </div>
  );
};

export default GroupList;
