import { MissionThreatsEnum } from "../Components/Basics/ThreatsSpriteMap";

export type MissionThreatType = {
  id: MissionThreatsEnum;
  name: string;
  counters: number[];
  isBeingCountered: boolean;
};

export const getMissionThreatTypesById = (
  missionThreatId: number,
  missionThreatTypes: MissionThreatType[]
) => {
  if (!missionThreatTypes) return;

  const filteredList = missionThreatTypes.filter(
    (missionThreatType) => missionThreatType.id === missionThreatId
  );

  if (filteredList.length === 0) return null;
  return filteredList[0];
};
