import React from "react";
import { ROUTE_HOME } from "../../Utils/routes";
import { NavLink } from "react-router-dom";

const Logo = () => {
  return (
    <div className="flex-shrink-0 flex items-center">
      <NavLink to={ROUTE_HOME}>
        <span className="block lg:hidden h-8 w-auto dark:text-white text-center text-3xl font-medium text-gray-50">
          Scrds Idle
        </span>
        <span className="hidden lg:block h-8 w-auto dark:text-white text-center text-3xl font-medium text-gray-50">
          Sacerdos Idle
        </span>
      </NavLink>
    </div>
  );
};

export default Logo;
