import { Hero } from "../Models/Hero";
import { Item } from "../Models/Item";

export function compareItemRarity(itemA: Item, itemB: Item) {
  if (itemA.rarityId > itemB.rarityId) return -1;
  if (itemA.rarityId < itemB.rarityId) return 1;
  return 0;
}

export function compareHeroRarity(heroA: Hero, heroB: Hero) {
  if (heroA.rarityId > heroB.rarityId) return -1;
  if (heroA.rarityId < heroB.rarityId) return 1;
  return 0;
}

export function compareHeroExp(heroA: Hero, heroB: Hero) {
  if (heroA.experience > heroB.experience) return -1;
  if (heroA.experience < heroB.experience) return 1;
  return 0;
}
