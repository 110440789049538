import { Hero } from "../../Models/Hero";
import { Item } from "../../Models/Item";
import { Rarity } from "../../Models/Rarity";
import Page from "../Basics/Page";
import ChangelogList from "../Changelog/ChangelogList";
import HeroSquareIcon from "../Hero/HeroSquareIcon";
import ItemIcon from "../Item/ItemIcon";
import Divider from "./Divider";
import { mockedHeroes, mockedItems } from "./MockedData";

const HomeIndex = () => {
  return (
    <Page name="Home" childrenClassName="mt-96">
      <div className="w-full flex flex-col text-gray-300">
        <h1 className="font-bold text-2xl mb-2 text-white">
          Welcome to Sacerdos Idle!
        </h1>
        <p className="mb-2">
          A free-to-play{" "}
          <span className="text-white font-bold">multiplayer</span> browser
          game.
        </p>
        <p>
          Gather your heroes in groups. Defeat Portals. Go on missions. Earn
          items and trade them!
        </p>
        <p className="mt-2">
          Strongly inspired by titles like the game{" "}
          <span className="text-white font-bold">Path of Exile</span> and the
          anime <span className="text-white font-bold">Solo Leveling</span>.
        </p>
        <p className="mt-2">
          This game has the goal to be your background task. You can play it
          while you work, study, watch something or even play another game.
        </p>
        <Divider />
        <h1 className="font-bold text-2xl text-white">Heroes</h1>
        <p>
          You earn currency and buy heroes from the store or from other players.
        </p>
        <div className="w-full grid grid-cols-4 mt-2 bg-gray-700 rounded-md p-3">
          {mockedHeroes.map((hero) => {
            return (
              <HeroSquareIcon key={`hero-square-${hero.id}`} hero={hero} />
            );
          })}
        </div>
        <Divider />
        <h1 className="font-bold text-2xl text-white">Currency</h1>
        <p>
          All the currency types have ability of changing the properties of
          other items. Its power will depend on its rarity.
        </p>
        <div className="w-full grid grid-cols-5 mt-2 mb-3 bg-gray-700 rounded-md p-3">
          {mockedItems.map((item, index) => {
            return (
              <ItemIcon
                key={`itemicongrid-${index}-${item ? item.id : "0"}`}
                item={item}
                index={index}
              />
            );
          })}
        </div>
        <Divider />
        <h1 className="font-bold text-2xl text-white">Rarities</h1>
        <p className="mb-2">
          Every item has a rarity. The game has 6 different rarities:{" "}
          <span className="text-scrds-gold font-bold">Ascended</span>,{" "}
          <span className="text-scrds-crimson font-bold">Mythic</span>,{" "}
          <span className="text-scrds-orange font-bold">Legendary</span>,{" "}
          <span className="text-scrds-magenta font-bold">Epic</span>,{" "}
          <span className="text-scrds-green font-bold">Rare</span>, and{" "}
          <span className="text-scrds-blue font-bold">Common</span>.
        </p>

        <p>
          The power of every artifect in this game depends on its rarity. The
          rarer, the better.
        </p>
        <Divider />
        <h1 className="font-bold text-2xl text-white">Changelog</h1>
        <ChangelogList />
      </div>
    </Page>
  );
};

export default HomeIndex;
