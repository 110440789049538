import * as React from "react";

const LockIcon = (props: any) => (
  <svg
    // eslint-disable-next-line react/destructuring-assignment
    className={`h-4 w-4 ${props.className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
  </svg>
);

export default LockIcon;
