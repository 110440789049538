import { heroClassesEnum } from "../Components/Basics/HeroesSpriteMap";

export type HeroSkillsType = {
  id: number;
  modType: number;
  heroSkillsTypeEnum: number;
  equippedByClass: heroClassesEnum[];
  itemType?: number;
};

export const getHeroSkillsTypeByItemType = (
  itemType: number,
  heroSkillsTypes: HeroSkillsType[]
) => {
  if (!heroSkillsTypes) return;

  const filteredList = heroSkillsTypes.filter(
    (heroSkillsType) => heroSkillsType.itemType === itemType
  );

  if (filteredList.length === 0) return null;
  return filteredList[0];
};
