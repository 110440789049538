import * as React from 'react';

const RemoveCircleIcon = (props: any) => (
  <svg
    // eslint-disable-next-line react/destructuring-assignment
    className={`w-6 h-6 ${props.className}`}
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 13H5v-2h14v2z" />
  </svg>
);

export default RemoveCircleIcon;
