import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDrop } from "react-dnd";
import useGroups from "../../Hooks/useGroups";
import useItems, { equipItemRequest } from "../../Hooks/useItems";
import { Group } from "../../Models/Group";
import { Hero } from "../../Models/Hero";
import { Item } from "../../Models/Item";
import { UserContext } from "../../Providers/UserProvider";
import getInitials from "../../Utils/getInitials";
import LoadingButton from "../Basics/LoadingButton";
import ProgressBar from "../Basics/ProgressBar";
import RoundAvatar from "../Basics/RoundAvatar";
import { equipItemIconDragType } from "../Item/ItemIcon";
import HeroCardItems from "./HeroCardItems";

type HeroCardProps = {
  allowDragItems?: boolean;
  showItemsTooltip?: boolean;
  userId: number;
  hero: Hero;
  sortedItems: Array<Item>;
  group: Group;
};

export type droppedItem = {
  id: number;
  index: number;
};

const HeroCard = ({
  userId,
  hero,
  sortedItems,
  allowDragItems = false,
  showItemsTooltip = false,
  group,
}: HeroCardProps) => {
  const { isAuthenticated } = useContext(UserContext);
  const ref = useRef(null);
  const [initials, setInitials] = useState<string>("aa");
  const [heroBg, setHeroBg] = useState<string>("bg-scrds-red");
  const [hasGroup, setHasGroup] = useState<boolean>(false);
  const [isGroupFull, setIsGroupFull] = useState<boolean>(false);
  const { equip: equipItem } = useItems(isAuthenticated);
  const { assign: assignHeroToGroup, unassing: unassignHeroToGroup } =
    useGroups(isAuthenticated);

  const [, drop] = useDrop({
    // Accept will make sure only these element type can be droppable on this element
    accept: equipItemIconDragType,
    drop(dropped: droppedItem) {
      const item = sortedItems[dropped.index];
      if (!item || item.isEquipped) return;

      const equipRequest: equipItemRequest = {
        userId,
        heroId: hero.id,
        itemId: item.id,
      };
      equipItem.mutate(equipRequest);
    },
  });

  const onAssignHeroToGroup = useCallback(async () => {
    const data = await assignHeroToGroup.mutateAsync({
      heroId: hero.id,
      groupId: group.id,
    });
  }, [userId, hero, assignHeroToGroup]);

  const onUnassignHeroToGroup = useCallback(async () => {
    const data = await unassignHeroToGroup.mutateAsync({
      heroId: hero.id,
      groupId: group.id,
    });
  }, [userId, hero, unassignHeroToGroup]);

  useEffect(() => {
    setInitials(getInitials(hero.heroClass.name));

    setHeroBg(
      `bg-scrds-${
        hero.rarity.color.isNamedColor
          ? hero.rarity.color.name.toLowerCase()
          : "red"
      }`
    );
  }, [hero]);

  useEffect(() => {
    if (!group) return;
    if (!group.heroes) {
      setHasGroup(false);
      return;
    }

    if (group.heroes.length >= 4) setIsGroupFull(true);

    group.heroes.forEach((groupHero) => {
      if (groupHero.id === hero.id) setHasGroup(true);
    });
  }, [group, hero]);

  drop(ref);

  return (
    <div className="relative w-full">
      <div
        ref={ref}
        className="flex h-40 w-full rounded-lg bg-gray-700 pl-16 shadow-xl border-2 border-transparent hover:border-gray-900 hover:cursor-pointer"
      >
        <div className="pt-2">
          <small className="my-auto text-lg font-medium text-slate-200">
            {hero.heroClass.name}
            <span className="text-xs ml-2 text-gray-400">
              Lvl. {hero.level}
            </span>
            <span className="text-xs ml-2 text-gray-400">
              Exp: {hero.experience - hero.previousExp}/
              {hero.nextExp - hero.previousExp}
            </span>
            <ProgressBar
              percentageValue={
                hero.nextExp === 0
                  ? 100
                  : ((hero.experience - hero.previousExp) /
                      (hero.nextExp - hero.previousExp)) *
                    100
              }
              bgColorClassName="bg-orange-400"
            />
            {/* <span className="pl-2 text-xs">
              {hasGroup ? (
                <LoadingButton
                  text="- Remove from group"
                  isLoading={unassignHeroToGroup.isLoading}
                  onClick={onUnassignHeroToGroup}
                />
              ) : (
                <>
                  {!isGroupFull ? (
                    <LoadingButton
                      text="+ Add to group"
                      isLoading={assignHeroToGroup.isLoading}
                      onClick={onAssignHeroToGroup}
                    />
                  ) : null}
                </>
              )}
            </span> */}
          </small>
          <div>
            <HeroCardItems
              items={hero.equippedItems}
              showItemsTooltip={showItemsTooltip}
              allowDragItems={allowDragItems}
            />
          </div>
        </div>
      </div>
      <div className="absolute -top-7 left-2 flex -space-x-1 overflow-hidden">
        <RoundAvatar text={initials} bgClassName={heroBg} />
      </div>
    </div>
  );
};

export default HeroCard;
