import React from "react";
import LoadingIcon from "../SVGIcons/LoadingIcon";
import SettingsIcon from "../SVGIcons/SettingsIcon";

type LoadingButtonProps = {
  text?: string;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
  icon?: JSX.Element;
};

const LoadingButton = ({
  text,
  isLoading = false,
  disabled = false,
  className = "border-gray-600",
  onClick,
  icon,
}: LoadingButtonProps) => {
  return (
    <div>
      <button
        type="button"
        className={`${className} flex items-center rounded-lg text-xs px-2 py-2 hover:cursor-pointer bg-gray-800 text-white border focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2`}
        disabled={isLoading || disabled}
        onClick={onClick}
      >
        {isLoading ? <LoadingIcon className="-ml-1 mr-3" /> : null}

        <div className="font-medium subpixel-antialiased flex gap-1 align-middle leading-5">
          {icon ? (
            <div className="flex h-5 justify-center flex-col">{icon}</div>
          ) : null}
          {isLoading ? "Loading..." : text}
        </div>
      </button>
    </div>
  );
};

export default LoadingButton;
