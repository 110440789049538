import { useContext, useEffect, useState } from "react";
import {
  getMissionThreatTypesById,
  MissionThreatType,
} from "../../Models/MissionThreatType";
import ThreatsSpriteMap from "../Basics/ThreatsSpriteMap";
import useEnemies from "../../Hooks/useEnemies";
import { getHeroSkillsTypeByItemType } from "../../Models/HeroSkillsType";
import { UserContext } from "../../Providers/UserProvider";
import { Hero } from "../../Models/Hero";

type PortalMissionProgressProps = {
  missionThreatEnumList: number[];
  successRate: number;
  maxIdleTimeInSeconds: number;
  instanceStartedAt: Date;
  heroes: Hero[];
};

const PortalMissionProgress = ({
  missionThreatEnumList,
  successRate,
  maxIdleTimeInSeconds,
  instanceStartedAt,
  heroes,
}: PortalMissionProgressProps) => {
  const { isAuthenticated } = useContext(UserContext);
  const {
    getMissionThreatTypesQuery: {
      data: missionThreatTypes,
      isFetching: isFetchingMissionThreatTypes,
    },
    getHeroSkillsTypesQuery: {
      data: heroSkillsTypes,
      isFetching: isFetchingHeroSkillsType,
    },
  } = useEnemies(isAuthenticated);
  const [missionThreatTypeList, setMissionThreatTypeList] =
    useState<(MissionThreatType | null | undefined)[]>();

  useEffect(() => {
    if (!missionThreatTypes || missionThreatTypes.length === 0) return;

    const heroSkills = heroSkillsTypes
      ? heroes.flatMap((hero) => {
          return hero.equippedItems.map((equippedItem) => {
            return getHeroSkillsTypeByItemType(
              equippedItem.itemTypeId,
              heroSkillsTypes
            );
          });
        })
      : [];

    console.log("heroSkills", heroSkills);
    const missionThreats = missionThreatEnumList.map((missionThreatId) => {
      const missionThreat = getMissionThreatTypesById(
        missionThreatId,
        missionThreatTypes
      );
      if (!missionThreat) return missionThreat;
      const skillsCountering = missionThreat.counters.filter(
        (counter) =>
          heroSkills.map((x) => x?.id).findIndex((x) => x === counter) !== -1
      );
      missionThreat.isBeingCountered = skillsCountering.length > 0;
      return missionThreat;
    });
    setMissionThreatTypeList(missionThreats);
  }, [missionThreatTypes, heroSkillsTypes, heroes, missionThreatEnumList]);

  return (
    <>
      <>
        <div className="w-full flex flex-row justify-between text-xs text-white mb-1">
          <span>Success chance: {(successRate! * 100).toFixed(0)}%</span>
        </div>
      </>
      {missionThreatTypeList ? (
        <div className="w-full text-xs text-white mb-1">
          <span>Mission Threats: </span>
          {missionThreatTypeList.map((missionThreatType, index) => {
            return (
              <span
                key={`missionThreatType-${missionThreatType?.id}`}
                className={`${
                  missionThreatType?.isBeingCountered
                    ? "text-scrds-lawngreen"
                    : ""
                }`}
              >
                <ThreatsSpriteMap missionThreat={missionThreatType?.id} />{" "}
                {missionThreatType?.name}
              </span>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default PortalMissionProgress;
