import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../Providers/UserProvider";
import Page from "../Basics/Page";
import FacebookIcon from "../SVGIcons/FacebookIcon";
import { LoadingContext } from "../../Providers/LoadingProvider";
import { NavLink } from "react-router-dom";
import { ROUTE_TERMS } from "../../Utils/routes";

const LoginIndex = () => {
  const { setOverlayIsLoading } = useContext(LoadingContext);
  const { setAccessToken, isLoading } = useContext(UserContext);

  const login = useCallback(async () => {
    window.FB.login(
      (response: any) => {
        if (
          response &&
          response.authResponse &&
          response.authResponse.accessToken
        ) {
          if (response.authResponse && response.authResponse.accessToken)
            setAccessToken(response.authResponse.accessToken);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile,email" }
    );
  }, [setAccessToken]);

  useEffect(() => {
    setOverlayIsLoading(isLoading);
  }, [isLoading, setOverlayIsLoading]);

  return (
    <Page name="Login">
      <div>
        <NavLink
          className={
            "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
          }
          to={ROUTE_TERMS}
        >
          Check our privacy policy
        </NavLink>
        <div className="flex w-full grid-cols-2">
          <button
            className="btn btn-facebook inline-flex p-2"
            onClick={login}
            type="button"
          >
            <FacebookIcon fill="#fff" />
            <span className="ml-2">Login with Facebook</span>
          </button>
        </div>
      </div>
    </Page>
  );
};

export default LoginIndex;
