import React, { useEffect, useState } from "react";
import AffixType from "../../Models/AffixType";
import { ModTag } from "../../Models/ModTag";
import Chip from "../Basics/Chip";

type AffixCardShowerLineProps = {
  keyString: string;
  affixType: AffixType;
  text: string;
  tags: ModTag[];
  value: number;
  className?: string;
};

const AffixCardShowerLine = ({
  text,
  affixType,
  tags,
  value,
  className,
  keyString,
}: AffixCardShowerLineProps) => {
  const [textParts, setTextParts] = useState<Array<string>>([]);

  useEffect(() => {
    if (text) {
      const parts = text.split("#");
      if (parts.length === 2) setTextParts(parts);
      if (parts.length === 1) setTextParts(parts);
    }
  }, [text]);

  if (!textParts) return <></>;

  return (
    <span className={`text-gray-200 ${className}`}>
      <span>{textParts[0]}</span>
      {textParts.length > 1 ? (
        <>
          <span className="text-lime-500 font-bold">{value.toString()}</span>
          <span>{textParts[1]}</span>
        </>
      ) : null}
      {tags.map((tag) => (
        <Chip
          text={tag.Name}
          key={`${keyString}-${tag.Name}`}
          bgClassName={`bg-scrds-${tag.tagColor.toLowerCase()}`}
        />
      ))}
      <span className="text-gray-500 font-bold ml-1">
        {AffixType[affixType][0].toUpperCase()}
      </span>
    </span>
  );
};

export default AffixCardShowerLine;
