import React from "react";
import { Hero } from "../../Models/Hero";
import ItemIcon from "../Item/ItemIcon";
import HeroCardItems from "./HeroCardItems";

type HeroEquipsProps = {
  hero: Hero;
  showItemsTooltip?: boolean;
};

const HeroEquips = ({ hero, showItemsTooltip }: HeroEquipsProps) => {
  const mainHandTypes = [10, 11, 12, 13, 14, 15, 16];
  const offHandTypes = [17, 18, 19];
  const chestTypes = [8];
  const feetTypes = [7];
  const headTypes = [9];
  return (
    <div className="w-full bg-gray-700">
      <div className="flex justify-center">
        <ItemIcon
          item={
            hero.equippedItems
              ? hero.equippedItems.filter((x) =>
                  headTypes.includes(x.itemTypeId)
                )[0]
              : undefined
          }
          index={0}
        />
      </div>
      <div className="flex justify-center">
        <ItemIcon
          item={
            hero.equippedItems
              ? hero.equippedItems.filter((x) =>
                  mainHandTypes.includes(x.itemTypeId)
                )[0]
              : undefined
          }
          index={0}
        />
        <ItemIcon
          item={
            hero.equippedItems
              ? hero.equippedItems.filter((x) =>
                  chestTypes.includes(x.itemTypeId)
                )[0]
              : undefined
          }
          index={0}
        />
        <ItemIcon
          item={
            hero.equippedItems
              ? hero.equippedItems.filter((x) =>
                  offHandTypes.includes(x.itemTypeId)
                )[0]
              : undefined
          }
          index={0}
        />
      </div>
      <div className="flex justify-center">
        <ItemIcon
          item={
            hero.equippedItems
              ? hero.equippedItems.filter((x) =>
                  feetTypes.includes(x.itemTypeId)
                )[0]
              : undefined
          }
          index={0}
        />
      </div>
    </div>
  );
};

export default HeroEquips;
