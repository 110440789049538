export default function getInitials(text: string) {
  if (!text) return '';
  let result = '';
  const exploded = text.split(' ');
  exploded.forEach((part: string) => {
    if (result.length >= 2) return;
    result = result.concat(part.length > 0 ? part[0].toUpperCase() : '');
  });
  return result;
}
