import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { User } from "../Models/User";
import { ROUTE_LOGIN } from "../Utils/routes";

export default function useUser(isAuthenticated: boolean) {
  const navigate = useNavigate();

  async function fetchSelfUser(): Promise<User> {
    const url = `${process.env.REACT_APP_API_URL}/Users/Self`;
    const res = await axios.get(url);

    if (!res || res.status !== 200) {
      navigate(ROUTE_LOGIN);
    }

    return res.data;
  }

  const query = useQuery("user", fetchSelfUser, { enabled: isAuthenticated });

  return query;
}
