import Page from "../Basics/Page";

const TermsPageIndex = () => {
  return (
    <Page name="Terms">
      <div>
        Privacy Policy for Sacerdos Idle Last Updated: 07/05/2023 At Sacerdos
        Idle ("we," "us," or "our"), we value and respect the privacy of our
        users. This Privacy Policy outlines how we collect, use, and protect
        your personal information when you use our website and services,
        specifically in connection with Facebook's Graph API Explorer. By using
        our website and services, you agree to the terms outlined in this
        Privacy Policy. We may update this Privacy Policy from time to time, so
        please check back regularly for any changes. Information We Collect When
        you use our website and services, we may collect the following
        information via Facebook's Graph API Explorer: 1. Email address: We
        collect your email address to identify you as a unique user and to
        provide you with important updates and notifications about our website
        and services. 2. Profile image: We collect your profile image to
        personalize your experience on our website and to allow you to easily
        recognize your account. How We Use Your Information We use the
        information we collect for the following purposes: 1. To provide and
        maintain our website and services, ensuring that they function properly
        and are tailored to your needs and preferences. 2. To communicate with
        you about your account, our services, updates, and any other information
        that may be relevant to your use of our website and services. 3. To
        improve our website and services by analyzing user behavior and
        identifying areas for enhancement. 4. To comply with legal obligations
        and protect the rights, property, and safety of Sacerdos Idle, our
        users, and the public. Sharing Your Information We do not sell or rent
        your personal information to third parties. We may share your
        information with third parties only in the following circumstances: 1.
        When required by law, such as to comply with a subpoena, court order, or
        legal process. 2. To protect our rights, property, or safety, or those
        of our users or the public, as necessary to prevent, detect, or
        investigate fraud, security breaches, or other potential risks. 3. With
        your explicit consent or at your direction, such as when you choose to
        share your information with other users of our website or services.
        Security We are committed to protecting the security of your personal
        information. We implement appropriate technical and organizational
        measures to safeguard your information from unauthorized access, use,
        disclosure, alteration, or destruction. However, please be aware that no
        method of transmission over the internet or method of electronic storage
        is 100% secure. While we strive to protect your information, we cannot
        guarantee its absolute security. Your Rights You have the right to
        access, update, or delete your personal information at any time. To
        exercise these rights, please contact us at sacerdosidle@gmail.com. We
        may require you to provide proof of your identity before we can process
        your request. Changes to This Privacy Policy We reserve the right to
        update or modify this Privacy Policy at any time. When we make changes,
        we will update the "Last Updated" date at the beginning of this Privacy
        Policy. Your continued use of our website and services following any
        changes to this Privacy Policy constitutes your acceptance of those
        changes. Contact Us If you have any questions, concerns, or comments
        about this Privacy Policy or our privacy practices, please contact us
        at: Sacerdos Idle Email: sacerdosidle@gmail.com We will respond to your
        inquiry as soon as possible.
      </div>
    </Page>
  );
};

export default TermsPageIndex;
