type ChangelogListItemProps = {
  date: string;
  items: { title: string; text: string }[];
};

const ChangelogListItem = ({ date, items }: ChangelogListItemProps) => {
  return (
    <div className="w-full flex flex-col gap-y-1">
      <h1>{date}</h1>
      <ul className="list-disc pl-5">
        {items.map((item, index) => {
          return (
            <li key={`${item.title}-${index}`}>
              <span>{item.title}</span> <span>- {item.text}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChangelogListItem;
