import { useCallback, useContext, useEffect, useState } from "react";
import { GroupWithInstance } from "../../Models/GroupWithInstance";
import { Hero } from "../../Models/Hero";
import Modal from "../Basics/Modal";
import { UserContext } from "../../Providers/UserProvider";
import LoadingButton from "../Basics/LoadingButton";
import { NavLink } from "react-router-dom";
import { ROUTE_UPGRADES } from "../../Utils/routes";
import PlusOneIcon from "../SVGIcons/PlusOneIcon";
import { LoadingContext } from "../../Providers/LoadingProvider";
import usePortalMissionInstances from "../../Hooks/usePortalMissionInstances";
import useItems from "../../Hooks/useItems";
import { Item } from "../../Models/Item";
import { compareItemRarity } from "../../Utils/compareRarity";
import StartPortalMissionItem from "./StartPortalMissionItem";

type StartPortalMissionModalProps = {
  groupId: number;
  group: GroupWithInstance;
  heroList: Hero[];
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

const StartPortalMissionModal = ({
  isModalOpen,
  setIsModalOpen,
  heroList,
  group,
  groupId,
}: StartPortalMissionModalProps) => {
  const { setOverlayIsLoading } = useContext(LoadingContext);
  const { isAuthenticated } = useContext(UserContext);
  const { start: startPortalMission } =
    usePortalMissionInstances(isAuthenticated);
  const [sortedItems, setSortedItems] = useState<Array<Item>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);
  const {
    getQuery: { data: itemsList, isFetching: isFetchingItemsList },
  } = useItems(isAuthenticated);

  useEffect(() => {
    if (!itemsList) return;
    setSortedItems(
      itemsList.filter((item) => item.itemTypeId === 30).sort(compareItemRarity)
    );
  }, [itemsList]);

  const onClickPortalMission = useCallback(
    async (itemId: number) => {
      if (!group) return;
      if (!itemId) return;
      if (selectedItems.includes(itemId)) {
        const newSelectedItems = selectedItems.filter((x) => x !== itemId);
        setSelectedItems(newSelectedItems);
      } else {
        setSelectedItems([...selectedItems, itemId]);
      }
    },
    [group, startPortalMission, selectedItems]
  );

  const onStartPortalMission = useCallback(async () => {
    if (!group) return;
    if (selectedItems.length === 0) return;
    const instData = await startPortalMission.mutateAsync({
      groupId: group.id,
      itemIds: selectedItems,
    });
    console.log("onStartPortalMission instData", instData);
  }, [group, startPortalMission, selectedItems]);

  const onStartAll = useCallback(async () => {
    if (!group) return;
    if (sortedItems.length === 0) return;
    const instData = await startPortalMission.mutateAsync({
      groupId: group.id,
      itemIds: sortedItems.map((x) => x.id),
    });
    console.log("onStartAll instData", instData);
  }, [group, sortedItems, startPortalMission]);

  if (!group) return <></>;
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="w-full bg-gray-700 m-auto p-6 border-2 border-gray-900 rounded-md">
        <div className="text-lg font-bold text-gray-300 text-center">
          Select Portal Mission ({sortedItems.length})
        </div>
        <div className="w-full grid grid-cols-1 mb-3 max-h-72 overflow-y-auto">
          {sortedItems.map((item) => {
            return (
              <div
                className={`relative ${
                  selectedItems.includes(item.id) ? "bg-gray-900" : ""
                }`}
                key={`portal-mission-${group.id}-${item.id}`}
                onClick={(e) => {
                  onClickPortalMission(item.id);
                  e.stopPropagation();
                  e.preventDefault();
                  e.nativeEvent.stopPropagation();
                  e.nativeEvent.preventDefault();
                }}
              >
                <StartPortalMissionItem item={item} />
              </div>
            );
          })}
        </div>
        <div className="w-full flex flex-row justify-around mt-3">
          <NavLink to={ROUTE_UPGRADES}>
            <LoadingButton
              icon={<PlusOneIcon className="h-5 w-5" fill="#fff" />}
              text="Buy more"
              onClick={() => {
                console.log("buy more");
              }}
            />
          </NavLink>
          <LoadingButton
            text="Start Missions"
            isLoading={startPortalMission.isLoading}
            onClick={() => {
              onStartPortalMission();
            }}
          />
          <LoadingButton
            text="Start All"
            isLoading={startPortalMission.isLoading}
            onClick={() => {
              onStartAll();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default StartPortalMissionModal;
