import React, { useContext } from "react";
import LoadingIcon from "../SVGIcons/LoadingIcon";
import { LoadingContext } from "../../Providers/LoadingProvider";
import Modal from "./Modal";

const LoadingOverlay = () => {
  const { isLoading } = useContext(LoadingContext);
  return (
    <Modal isOpen={isLoading} onClose={() => {}} zIndex={50}>
      {isLoading ? (
        <div className="flex flex-col text-white items-center p-8">
          <LoadingIcon className="h-8 w-8 mb-4" /> Loading...
        </div>
      ) : null}
    </Modal>
  );
};

export default LoadingOverlay;
