import React, { useContext } from "react";
import {
  ROUTE_HEROES,
  ROUTE_HOME,
  ROUTE_ITEMS,
  ROUTE_LOGIN,
  ROUTE_PLAY,
  ROUTE_UPGRADES,
} from "../../Utils/routes";
import FooterMenuButton from "./FooterMenuButton";
import PlusOneIcon from "../SVGIcons/PlusOneIcon";
import GroupIcon from "../SVGIcons/GroupIcon";
import GameIcon from "../SVGIcons/GameIcon";
import InventoryIcon from "../SVGIcons/InventoryIcon";
import useUser from "../../Hooks/useUser";
import { UserContext } from "../../Providers/UserProvider";

const FooterMenu = () => {
  const { isAuthenticated } = useContext(UserContext);

  if (!isAuthenticated)
    return (
      <footer className="bg-gray-800 text-white text-center">
        <div className="grid grid-cols-1">
          <FooterMenuButton
            pathname={ROUTE_LOGIN}
            selectedBackground="bg-gray-600"
            unselectedBackground="bg-scrds-green"
            text="Play"
            icon={<GameIcon className="h-5 w-5" fill="#fff" />}
          />
        </div>
      </footer>
    );

  return (
    <footer className="bg-gray-800 text-white text-center">
      <div className="grid grid-cols-4">
        <div className={`border-r border-gray-600 w-full`}>
          <FooterMenuButton
            pathname={ROUTE_PLAY}
            selectedBackground="bg-gray-600"
            unselectedBackground="bg-scrds-green"
            text="Play"
            icon={<GameIcon className="h-5 w-5" fill="#fff" />}
          />
        </div>
        <div className={`border-r border-gray-600 w-full`}>
          <FooterMenuButton
            pathname={ROUTE_ITEMS}
            selectedBackground="bg-gray-600"
            unselectedBackground=""
            text="Inventory"
            icon={<InventoryIcon className="h-5 w-5" fill="#fff" />}
          />
        </div>
        <div className={`border-r border-gray-600 w-full`}>
          <FooterMenuButton
            pathname={ROUTE_HEROES}
            selectedBackground="bg-gray-600"
            unselectedBackground=""
            text="Heroes"
            icon={<GroupIcon className="h-5 w-5" fill="#fff" />}
          />
        </div>
        <div className={`w-full`}>
          <FooterMenuButton
            pathname={ROUTE_UPGRADES}
            selectedBackground="bg-gray-600"
            unselectedBackground="bg-scrds-orange"
            text="Upgrades"
            icon={<PlusOneIcon className="h-5 w-5" fill="#fff" />}
          />
        </div>
      </div>
    </footer>
  );
};

export default FooterMenu;
