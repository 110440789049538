import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Hero } from "../Models/Hero";
import { useCallback } from "react";
import useGroups from "./useGroups";

async function createHero(userId: number): Promise<Hero> {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/Heroes`, {
    userId,
  });
  return res.data;
}

async function getList(): Promise<Hero[]> {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Heroes/GetList`
  );
  return res.data;
}

export default function useHeroes(isAuthenticated: boolean) {
  const queryClient = useQueryClient();
  const {
    getQuery: { data: groupsWithInstance },
  } = useGroups(isAuthenticated);

  const getListQuery = useQuery(["heroes", "list"], getList, {
    enabled: isAuthenticated,
  });

  const getGroupByHero = useCallback(
    (hero: Hero) => {
      if (!groupsWithInstance) return null;

      if (hero.groupId) {
        const groups = groupsWithInstance.filter((x) => x.id === hero.groupId);
        if (groups.length === 1) return groups[0];
      }
    },
    [groupsWithInstance]
  );

  const add = useMutation(createHero, {
    onSuccess: (addedHero) => {
      queryClient.setQueryData(
        "heroes",
        (currentHeroes: Array<Hero> | undefined) => {
          queryClient.invalidateQueries(["user"]);
          if (currentHeroes === undefined) return [];
          return [...currentHeroes, addedHero];
        }
      );
    },
  });

  return { add, getListQuery, getGroupByHero };
}
