import React from 'react';

type ChipProps = {
  text: string;
  bgClassName?: string;
};

const Chip = ({ text, bgClassName = 'bg-red-500' }: ChipProps) => {
  return (
    <span
      className={`${bgClassName} text-xs leading-none justify-center items-center ml-1 font-medium px-2 rounded-full text-white`}
    >
      {text}
    </span>
  );
};

export default Chip;
