import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIdentity from "../../Hooks/useIdentity";
import useUser from "../../Hooks/useUser";
import { ROUTE_LOGIN } from "../../Utils/routes";
import initFacebookSdk from "../../_helpers/init-facebook-sdk";

interface Props {
  children: React.ReactNode;
}

export type UserContextType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  authToken: string;
  setAccessToken: (newToken: string) => void;
};

export const UserContext = React.createContext<UserContextType>({
  isAuthenticated: false,
  isLoading: false,
  authToken: "",
  setAccessToken: () => {},
});

export const UserProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const {
    login,
    authToken,
    isLoading: isFetchingLogin,
  } = useIdentity(setIsAuthenticated);
  const {
    data: user,
    isFetching: isFetchingUser,
    isError,
    error,
  } = useUser(isAuthenticated);

  initFacebookSdk(login);

  // useEffect(() => {
  //   console.log("isError", isError, "error", error);
  //   if (isError) {
  //     navigate(ROUTE_LOGIN);
  //   }
  // }, [isError, error, navigate]);

  useEffect(() => {
    if (accessToken === "") return;
    login(accessToken);
  }, [accessToken]);

  // useEffect(() => {
  //   if (accessToken === "" || !accessToken) setIsAuthenticated(false);
  //   if (accessToken && accessToken !== "") setIsAuthenticated(true);
  // }, [accessToken]);

  const userContext: UserContextType = {
    // We should also consider `isFetching` to take invalidating queries into account as well.
    isAuthenticated: isAuthenticated,
    isLoading: isFetchingLogin || isFetchingUser,
    authToken,
    setAccessToken,
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};
