import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  ROUTE_HEROES,
  ROUTE_HOME,
  ROUTE_ITEMS,
  ROUTE_UPGRADES,
} from "../../Utils/routes";

type FooterMenuButtonProps = {
  unselectedBackground: string;
  selectedBackground: string;
  pathname: string;
  text: string;
  icon?: JSX.Element;
};

const FooterMenuButton = ({
  unselectedBackground,
  selectedBackground,
  pathname,
  text,
  icon,
}: FooterMenuButtonProps) => {
  const location = useLocation();
  return (
    <NavLink
      to={pathname}
      className={`py-3 w-full flex flex-col justify-center items-center ${
        location.pathname === pathname
          ? selectedBackground
          : unselectedBackground
      }`}
    >
      {icon}
      <div className="text-xs">{text}</div>
    </NavLink>
  );
};

export default FooterMenuButton;
