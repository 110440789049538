import React from "react";

interface ModalProps extends React.ComponentPropsWithoutRef<"div"> {
  isOpen: boolean;
  zIndex?: number;
  onClose: () => void;
}

const Modal = ({ children, isOpen, onClose, zIndex = 40 }: ModalProps) => {
  return (
    <div
      data-modal-show="true"
      aria-hidden="true"
      className={`overflow-x-hidden flex overflow-y-auto fixed h-full left-0 right-0 inset-0 z-${zIndex} justify-center items-center bg-gray-700/75 ${
        isOpen ? "" : "hidden"
      } `}
      onClick={(ev) => {
        onClose();
        ev.stopPropagation();
      }}
    >
      <div
        aria-hidden="true"
        className="relative w-fit m-auto max-w-2xl h-full h-auto"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
      >
        <div className="rounded-lg relative bg-gray-800 shadow-lg">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
