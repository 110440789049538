import { useContext, useState } from "react";
import { UserContext } from "../../Providers/UserProvider";
import Page from "../Basics/Page";
import CurrencyDisplay from "./CurrencyDisplay";
import UpgradeCard from "./UpgradeCard";
import useUpgrades from "../../Hooks/useUpgrades";
import useUser from "../../Hooks/useUser";
import { LoadingContext } from "../../Providers/LoadingProvider";
import Congratulations from "../Basics/Congratulations";
import Modal from "../Basics/Modal";
import { BuyResult } from "../../Models/BuyResult";
import HeroSquareIcon from "../Hero/HeroSquareIcon";
import { Hero } from "../../Models/Hero";
import { currenciesTypesEnum } from "../Basics/CurrenciesSpriteMap";
import useGroups from "../../Hooks/useGroups";
import useHeroes from "../../Hooks/useHeroes";

enum UpgradeTypes {
  NEW_FREE_GROUP = 0,
  NEW_FREE_RANDOM_HERO = 1,
  NEW_GROUP = 2,
  NEW_RANDOM_HERO = 3,
  COMMON_PORTAL_MISSION = 4,
  COMMON_PORTAL_MISSION_x10 = 5,
  COMMON_PORTAL_MISSION_x100 = 6,
}

export type Upgrade = {
  id: UpgradeTypes;
  title: string;
  subtitle: string;
  description: string;
  cost: number;
  costCurrency: currenciesTypesEnum;
  onClick: () => void;
};

const UpgradesIndex = () => {
  const { isAuthenticated } = useContext(UserContext);
  const { data: user, isFetching } = useUser(isAuthenticated);
  const {
    getQuery: { data: groupsWithInstance, isFetching: isFetchingGroup },
  } = useGroups(isAuthenticated);
  const {
    getListQuery: { data: heroes, isFetching: isFetchingHeroList },
  } = useHeroes(isAuthenticated);
  const {
    buyNewRandomHero,
    buyNewFreeRandomHero,
    buyNewFreeGroup,
    buyNewGroup,
    buyNewItem,
  } = useUpgrades(isAuthenticated);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [buyHeroResult, setBuyHeroResult] = useState<
    BuyResult<Hero> | undefined
  >(undefined);
  const { isLoading, setOverlayIsLoading } = useContext(LoadingContext);

  const upgradeList: Record<UpgradeTypes, Upgrade> = {
    [UpgradeTypes.NEW_FREE_GROUP]: {
      id: UpgradeTypes.NEW_FREE_GROUP,
      title: "Free Group",
      subtitle:
        "Buy you first group to manage your heroes. Start your journey.",
      description: "This group has 5 slots for heroes. (heroes not included)",
      cost: 0,
      costCurrency: currenciesTypesEnum.CHAOTIC_ORB,
      onClick: async () => {
        // Handle creating a new group
        setOverlayIsLoading(true);
        var buyGroupResultResponse = await buyNewFreeGroup.mutateAsync();
        setOverlayIsLoading(false);
        console.log("buyGroupResultResponse", buyGroupResultResponse);
        // if (buyHeroResultResponse) {
        //   setBuyHeroResult(buyHeroResultResponse);
        //   setShowCongratulations(true);
        //   setTimeout(() => {
        //     setShowCongratulations(false);
        //   }, 1000);
        // }
      },
    },
    [UpgradeTypes.NEW_FREE_RANDOM_HERO]: {
      id: UpgradeTypes.NEW_FREE_RANDOM_HERO,
      title: "Free Random Hero",
      subtitle:
        "Summon a new hero to join your team and strengthen your forces.",
      description: "The rarity and the Hero Class are random. Good luck!",
      cost: 0,
      costCurrency: currenciesTypesEnum.COMMONIZER_ORB,
      onClick: async () => {
        // Handle creating a new group
        setOverlayIsLoading(true);
        var buyHeroResultResponse = await buyNewFreeRandomHero.mutateAsync();
        setOverlayIsLoading(false);
        console.log("buyHeroResultResponse", buyHeroResultResponse);
        if (buyHeroResultResponse) {
          setBuyHeroResult(buyHeroResultResponse);
          setShowCongratulations(true);
          setTimeout(() => {
            setShowCongratulations(false);
          }, 1000);
        }
      },
    },
    [UpgradeTypes.NEW_GROUP]: {
      id: UpgradeTypes.NEW_GROUP,
      title: "New Group",
      subtitle: "Buy new group to manage your heroes. Speed up your gains.",
      description: "This group has 5 slots for heroes. (heroes not included)",
      cost: 1,
      costCurrency: currenciesTypesEnum.CHAOTIC_ORB,
      onClick: async () => {
        // Handle creating a new group
        setOverlayIsLoading(true);
        var buyGroupResultResponse = await buyNewGroup.mutateAsync();
        setOverlayIsLoading(false);
        console.log("buyGroupResultResponse", buyGroupResultResponse);
        // if (buyHeroResultResponse) {
        //   setBuyHeroResult(buyHeroResultResponse);
        //   setShowCongratulations(true);
        //   setTimeout(() => {
        //     setShowCongratulations(false);
        //   }, 1000);
        // }
      },
    },
    [UpgradeTypes.NEW_RANDOM_HERO]: {
      id: UpgradeTypes.NEW_RANDOM_HERO,
      title: "New Random Hero",
      subtitle:
        "Summon a new hero to join your team and strengthen your forces.",
      description: "The rarity and the Hero Class are random. Good luck!",
      cost: 1,
      costCurrency: currenciesTypesEnum.COMMONIZER_ORB,
      onClick: async () => {
        // Handle creating a new group
        setOverlayIsLoading(true);
        var buyHeroResultResponse = await buyNewRandomHero.mutateAsync();
        setOverlayIsLoading(false);
        console.log("buyHeroResultResponse", buyHeroResultResponse);
        if (buyHeroResultResponse) {
          setBuyHeroResult(buyHeroResultResponse);
          setShowCongratulations(true);
          setTimeout(() => {
            setShowCongratulations(false);
          }, 1000);
        }
      },
    },
    [UpgradeTypes.COMMON_PORTAL_MISSION]: {
      id: UpgradeTypes.COMMON_PORTAL_MISSION,
      title: "Common Portal Mission",
      subtitle:
        "Buy the right of going to a mission, kill all the enemies and receive some reward.",
      description: "If you defeat it, you can be reward with rarer missions.",
      cost: 1,
      costCurrency: currenciesTypesEnum.SUPER_COMMON_ORB,
      onClick: async () => {
        // Handle creating a new group
        setOverlayIsLoading(true);
        var buyNewItemResultResponse = await buyNewItem.mutateAsync({
          quantity: 1,
        });
        setOverlayIsLoading(false);
        console.log("buyNewItemResultResponse", buyNewItemResultResponse);
        // if (buyNewItemResultResponse) {
        //   setBuyHeroResult(buyHeroResultResponse);
        //   setShowCongratulations(true);
        //   setTimeout(() => {
        //     setShowCongratulations(false);
        //   }, 1000);
        // }
      },
    },
    [UpgradeTypes.COMMON_PORTAL_MISSION_x10]: {
      id: UpgradeTypes.COMMON_PORTAL_MISSION_x10,
      title: "10x Common Portal Mission",
      subtitle:
        "Buy the right of going to a mission, kill all the enemies and receive some reward.",
      description: "If you defeat it, you can be reward with rarer missions.",
      cost: 10,
      costCurrency: currenciesTypesEnum.SUPER_COMMON_ORB,
      onClick: async () => {
        // Handle creating a new group
        setOverlayIsLoading(true);
        var buyNewItemResultResponse = await buyNewItem.mutateAsync({
          quantity: 10,
        });
        setOverlayIsLoading(false);
        console.log("buyNewItemResultResponse", buyNewItemResultResponse);
        // if (buyNewItemResultResponse) {
        //   setBuyHeroResult(buyHeroResultResponse);
        //   setShowCongratulations(true);
        //   setTimeout(() => {
        //     setShowCongratulations(false);
        //   }, 1000);
        // }
      },
    },
    [UpgradeTypes.COMMON_PORTAL_MISSION_x100]: {
      id: UpgradeTypes.COMMON_PORTAL_MISSION_x100,
      title: "100x Common Portal Mission",
      subtitle:
        "Buy the right of going to a mission, kill all the enemies and receive some reward.",
      description: "If you defeat it, you can be reward with rarer missions.",
      cost: 100,
      costCurrency: currenciesTypesEnum.SUPER_COMMON_ORB,
      onClick: async () => {
        // Handle creating a new group
        setOverlayIsLoading(true);
        var buyNewItemResultResponse = await buyNewItem.mutateAsync({
          quantity: 100,
        });
        setOverlayIsLoading(false);
        console.log("buyNewItemResultResponse", buyNewItemResultResponse);
        // if (buyNewItemResultResponse) {
        //   setBuyHeroResult(buyHeroResultResponse);
        //   setShowCongratulations(true);
        //   setTimeout(() => {
        //     setShowCongratulations(false);
        //   }, 1000);
        // }
      },
    },
  };

  if (!user) return <></>;
  return (
    <Page
      name="Upgrades"
      backgroundSrc="./sprites/vendor2.jpg"
      childrenClassName="mt-96"
    >
      <div className="container mx-auto">
        <CurrencyDisplay />
        {!!buyHeroResult ? (
          <>
            <Modal
              isOpen={!!buyHeroResult}
              onClose={() => {
                setBuyHeroResult(undefined);
              }}
            >
              <div className="px-6 py-2 flex flex-col items-center border-2 rounded-lg border-gray-900">
                <div className="text-white text-lg text-center w-full mb-2">
                  You received
                </div>
                <div
                  className={`font-bold text-scrds-${buyHeroResult?.itemsBought[0].rarity.color.name.toLowerCase()} text-lg text-center w-full`}
                >
                  {buyHeroResult?.itemsBought[0].rarity.name}
                </div>
                <div className={`text-white text-lg text-center w-full mb-2`}>
                  {buyHeroResult?.itemsBought[0].heroClass.name}
                </div>
                <div className="w-14 mb-2">
                  <HeroSquareIcon hero={buyHeroResult?.itemsBought[0]} />
                </div>
              </div>
            </Modal>
            <Congratulations
              show={showCongratulations}
              rarity={buyHeroResult!.itemsBought[0].rarity}
            />
          </>
        ) : null}

        <div className="w-full flex flex-col gap-y-4 mt-4">
          {!groupsWithInstance || groupsWithInstance.length < 1 ? (
            <UpgradeCard upgrade={upgradeList[UpgradeTypes.NEW_FREE_GROUP]} />
          ) : (
            <UpgradeCard upgrade={upgradeList[UpgradeTypes.NEW_GROUP]} />
          )}
          {!heroes || heroes.length < 1 ? (
            <UpgradeCard
              upgrade={upgradeList[UpgradeTypes.NEW_FREE_RANDOM_HERO]}
            />
          ) : (
            <UpgradeCard upgrade={upgradeList[UpgradeTypes.NEW_RANDOM_HERO]} />
          )}
          <UpgradeCard
            upgrade={upgradeList[UpgradeTypes.COMMON_PORTAL_MISSION]}
          />
          <UpgradeCard
            upgrade={upgradeList[UpgradeTypes.COMMON_PORTAL_MISSION_x10]}
          />
          <UpgradeCard
            upgrade={upgradeList[UpgradeTypes.COMMON_PORTAL_MISSION_x100]}
          />
        </div>
      </div>
    </Page>
  );
};

export default UpgradesIndex;
