import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { BuyResult } from "../Models/BuyResult";
import { Hero } from "../Models/Hero";
import { Group } from "../Models/Group";
import { Item } from "../Models/Item";

export type BuyItemRequest = {
  quantity: number;
};

export type BuyItemResponse = {
  data: BuyResult<Item> | null;
  param: BuyItemRequest;
};

async function postBuyNewRandomHero(): Promise<BuyResult<Hero> | null> {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/Upgrades/BuyNewRandomHero`,
      {}
    );

    if (res && res.status === 200) return res.data;
    return null;
  } catch (error) {
    console.error("error", error);
    return null;
  }
}
async function postBuyNewFreeRandomHero(): Promise<BuyResult<Hero> | null> {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/Upgrades/BuyFreeNewRandomHero`,
      {}
    );

    if (res && res.status === 200) return res.data;
    return null;
  } catch (error) {
    console.error("error", error);
    return null;
  }
}
async function postBuyNewFreeGroup(): Promise<BuyResult<Group> | null> {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/Upgrades/BuyFreeNewGroup`,
      {}
    );

    if (res && res.status === 200) return res.data;
    return null;
  } catch (error) {
    console.error("error", error);
    return null;
  }
}
async function postBuyNewGroup(): Promise<BuyResult<Group> | null> {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/Upgrades/BuyNewGroup`,
      {}
    );

    if (res && res.status === 200) return res.data;
    return null;
  } catch (error) {
    console.error("error", error);
    return null;
  }
}
async function postBuyNewItem(
  param: BuyItemRequest
): Promise<BuyItemResponse | null> {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/Upgrades/BuyNewItem`,
      { ...param }
    );

    if (res && res.status === 200) return { data: res.data, param };
    return null;
  } catch (error) {
    console.error("error", error);
    return null;
  }
}

export default function useUpgrades(isAuthenticated: boolean) {
  const queryClient = useQueryClient();

  const buyNewRandomHero = useMutation(postBuyNewRandomHero, {
    onSuccess: (buyHeroResult) => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries("item");
    },
  });

  const buyNewFreeRandomHero = useMutation(postBuyNewFreeRandomHero, {
    onSuccess: (buyHeroResult) => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries("item");
    },
  });

  const buyNewFreeGroup = useMutation(postBuyNewFreeGroup, {
    onSuccess: (buyGroupResult) => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["group", "list"]);
    },
  });

  const buyNewGroup = useMutation(postBuyNewGroup, {
    onSuccess: (buyGroupResult) => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["group", "list"]);
    },
  });

  const buyNewItem = useMutation(postBuyNewItem, {
    onSuccess: (buyItemResult) => {
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["item", "list"]);
    },
  });

  return {
    buyNewRandomHero,
    buyNewFreeRandomHero,
    buyNewFreeGroup,
    buyNewGroup,
    buyNewItem,
  };
}
