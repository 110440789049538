import * as React from "react";

const GroupAddIcon = (props: any) => (
  <svg
    // eslint-disable-next-line react/destructuring-assignment
    className={`h-4 w-4 ${props.className}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z" />
  </svg>
);

export default GroupAddIcon;
