import axios, { Axios } from "axios";

// import useIdentity from '../Hooks/useIdentity';

export default function jwtInterceptor(authToken, setIsAuthenticated) {
  axios.interceptors.request.use(
    (request) => {
      // add auth header with jwt if account is logged in and request is to the api url
      const isLoggedIn = authToken !== "";
      const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);

      if (isLoggedIn && isApiUrl) {
        request.headers["Authorization"] = `Bearer ${authToken}`;
      }

      // console.log("request", request);
      return request;
    },
    (error) => {
      // console.log("error", error.response, error.response.status);
      setIsAuthenticated(false);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // console.log("response", response);
      if (response.status !== 200) {
        setIsAuthenticated(false);
      }
      return response;
    },
    (error) => {
      if (error.response.status === 500) {
        // Handle the error here, such as logging it or sending it to an error tracking service
        console.log("jwtInterceptor error", error);
      } else if (error.response && error.response.status !== 200) {
        setIsAuthenticated(false);
      }

      return Promise.reject(error);
    }
  );
}
