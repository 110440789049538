import { useCallback, useContext, useEffect, useState } from "react";
import useIdleInstances from "../../Hooks/useIdleInstances";
import { GroupWithInstance } from "../../Models/GroupWithInstance";
import { Hero } from "../../Models/Hero";
import { InstanceData } from "../../Models/InstanceData";
import { UserContext } from "../../Providers/UserProvider";
import { compareHeroExp, compareHeroRarity } from "../../Utils/compareRarity";
import LoadingButton from "../Basics/LoadingButton";
import Modal from "../Basics/Modal";
import AssignHeroToGroupModal from "../Hero/AssignHeroToGroupModal";
import HeroSquareIcon from "../Hero/HeroSquareIcon";
import ItemGrid from "../Item/ItemGrid";
import GroupAddIcon from "../SVGIcons/GroupAddIcon";
import usePortalMissionInstances from "../../Hooks/usePortalMissionInstances";
import { MissionThreatType } from "../../Models/MissionThreatType";
import ThreatsSpriteMap from "../Basics/ThreatsSpriteMap";
import StartPortalMissionModal from "./StartPortalMissionModal";
import PortalMissionProgress from "./PortalMissionProgress";
import useCountdown from "../../Hooks/useCountdown";
import ProgressBar from "../Basics/ProgressBar";
import { RewardsContext } from "../../Providers/RewardsProvider";

type GroupHeroesListCardProps = {
  group: GroupWithInstance;
  heroList: Hero[];
};

const groupListSize = 5;
const listArray: number[] = [];
for (let index = 0; index < groupListSize; index += 1) {
  listArray.push(index);
}

const GroupCard = ({ group, heroList }: GroupHeroesListCardProps) => {
  const { isAuthenticated } = useContext(UserContext);
  const { addReward } = useContext(RewardsContext);
  const [sortedHerolist, setSortedHeroList] = useState<Hero[]>([]);
  const [isAssignToGroupModalOpen, setIsAssignToGroupModalOpen] =
    useState<boolean>(false);
  const [isStartPortalMissionModalOpen, setIsStartPortalMissionModalOpen] =
    useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(true);
  const {
    start: startIdleInstance,
    stopIdle: stoptIdleInstance,
    stopPortalMission,
  } = useIdleInstances(isAuthenticated);
  const { start: startPortalMission } =
    usePortalMissionInstances(isAuthenticated);

  const onStartIdleInstance = useCallback(async () => {
    if (!group) return;
    const instData = await startIdleInstance.mutateAsync({
      groupId: group.id,
    });
  }, [group, startIdleInstance]);

  const onStopIdleInstance = useCallback(
    async (isIdle: boolean | undefined) => {
      if (!group) return;
      let instData = null;
      if (isIdle) {
        instData = await stoptIdleInstance.mutateAsync({
          groupId: group.id,
        });
      } else {
        instData = await stopPortalMission.mutateAsync({
          groupId: group.id,
        });
      }
      addReward(group.heroes, instData.instanceData);
    },
    [group, addReward, stoptIdleInstance, stopPortalMission]
  );

  useEffect(() => {
    if (!group || !group.heroes) {
      setSortedHeroList([]);
      return;
    }
    setSortedHeroList(
      group.heroes.sort(compareHeroRarity).sort(compareHeroExp)
    );
  }, [group]);

  const timeLeft = useCountdown(group.instanceEndTime);
  console.log("timeLeft", timeLeft);

  const getTimeleftString = useCallback((timeInSeconds: number | null) => {
    const timeleftInSeconds =
      timeInSeconds === null || timeInSeconds < 0 ? 0 : timeInSeconds;

    const minutes = Math.floor(timeleftInSeconds / 60);
    const hours = Math.floor(minutes / 60);

    const string =
      timeleftInSeconds === 0
        ? "Completed"
        : `${hours > 0 ? `${hours}h` : ""} ${
            minutes >= 0 ? `${minutes % 60}min` : ""
          } ${timeleftInSeconds >= 0 ? `${timeleftInSeconds % 60}sec` : ""}`;

    return string;
  }, []);

  return (
    <div className="w-full flex flex-row justify-around">
      <div className="w-full bg-gray-700/50 rounded-md p-3">
        <div className="w-full flex flex-row justify-between text-xs text-white mb-1">
          <span className="text-gray-400">Group</span>{" "}
          {!group.isBusy ? (
            <LoadingButton
              icon={<GroupAddIcon fill="#fff" />}
              text="Heroes"
              onClick={() => {
                setIsAssignToGroupModalOpen(true);
              }}
            />
          ) : null}
        </div>
        <div className="w-full grid grid-cols-5 mb-3">
          {listArray.map((elem) => {
            const num = Number(elem);
            const hero = sortedHerolist ? sortedHerolist[num] : undefined;
            return (
              <HeroSquareIcon
                key={`hero-square-${group.id}-${num}`}
                hero={hero}
              />
            );
          })}
        </div>
        {group.isBusy ? (
          <>
            <div className="w-full flex flex-row justify-between text-xs text-white mb-1">
              {group.isIdle ? (
                <span>
                  Exp:{" "}
                  {(
                    group.idleInstanceDataParsed?.expPerMonster! *
                    group.idleInstanceDataParsed?.spawnRatePerSecond!
                  ).toFixed(0)}
                  /sec
                </span>
              ) : null}

              <span className="text-gray-400">
                Timeleft: {getTimeleftString(timeLeft)}
              </span>
            </div>
            <ProgressBar
              percentageValue={
                timeLeft !== null && group.maxIdleTimeInSeconds
                  ? ((group.maxIdleTimeInSeconds - timeLeft) /
                      group.maxIdleTimeInSeconds) *
                    100
                  : 0
              }
            />
          </>
        ) : null}
        <div className="w-full flex flex-row justify-around mt-3">
          {group.isBusy && group.instanceEndTime ? (
            timeLeft !== null && timeLeft <= 0 ? (
              <>
                <LoadingButton
                  text="Get Rewards"
                  isLoading={
                    stoptIdleInstance.isLoading || stopPortalMission.isLoading
                  }
                  onClick={() => {
                    onStopIdleInstance(group.isIdle);
                  }}
                />
              </>
            ) : group.isIdle ? (
              <>
                <LoadingButton
                  text="Stop Training"
                  isLoading={stoptIdleInstance.isLoading}
                  onClick={() => {
                    onStopIdleInstance(true);
                  }}
                />
              </>
            ) : null
          ) : group.heroes && group.heroes.length > 0 ? (
            <>
              <LoadingButton
                text="Training"
                isLoading={startIdleInstance.isLoading}
                onClick={onStartIdleInstance}
              />
              <LoadingButton
                text="Start Mission"
                isLoading={startPortalMission.isLoading}
                onClick={() => {
                  setIsStartPortalMissionModalOpen(true);
                }}
              />
            </>
          ) : null}
        </div>

        <div className="w-full flex flex-col justify-around mt-3">
          {group.isBusy && !group.isIdle ? (
            <>
              <LoadingButton
                text={showInfo ? "Hide info" : "Show info"}
                onClick={() => {
                  setShowInfo(!showInfo);
                }}
              />
              {showInfo
                ? group.idleInstanceDataParsed?.instancesInChain.map(
                    (instanceInChain, index) => {
                      return (
                        <PortalMissionProgress
                          key={`portal-mission-${index}`}
                          missionThreatEnumList={instanceInChain.missionThreats}
                          successRate={instanceInChain.successRate}
                          maxIdleTimeInSeconds={instanceInChain.maxDuration}
                          instanceStartedAt={instanceInChain.startTime}
                          heroes={group.heroes}
                        />
                      );
                    }
                  )
                : null}
            </>
          ) : null}
        </div>
      </div>
      <AssignHeroToGroupModal
        heroList={heroList}
        isModalOpen={isAssignToGroupModalOpen}
        setIsModalOpen={setIsAssignToGroupModalOpen}
        group={group}
        groupId={group.id}
      />
      <StartPortalMissionModal
        heroList={heroList}
        isModalOpen={isStartPortalMissionModalOpen}
        setIsModalOpen={setIsStartPortalMissionModalOpen}
        group={group}
        groupId={group.id}
      />
    </div>
  );
};

export default GroupCard;
