import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Item } from "../Models/Item";

export type createItemRequest = {
  userId: number;
  iLevel: number;
};

export type equipItemRequest = {
  userId: number;
  heroId: number;
  itemId: number;
};

export type createItemResponse = {
  item: Item;
  param: createItemRequest;
};

export type equipItemResponse = {
  item: Item;
  param: equipItemRequest;
};

export type scrapItemsResponse = {
  items: Item[];
};

async function createItem(
  param: createItemRequest
): Promise<createItemResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Items/Create`,
    {
      ...param,
    }
  );
  return { item: res.data, param };
}

async function equipItem(param: equipItemRequest): Promise<equipItemResponse> {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/Items/Equip`, {
    ...param,
  });
  return { item: res.data, param };
}

async function scrapAllCommonItems(): Promise<scrapItemsResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Items/ScrapAll`,
    {
      rarityId: 0,
    }
  );
  return { items: res.data };
}

async function scrapAllRareItems(): Promise<scrapItemsResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Items/ScrapAll`,
    {
      rarityId: 1,
    }
  );
  return { items: res.data };
}

async function scrapAllEpicItems(): Promise<scrapItemsResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Items/ScrapAll`,
    {
      rarityId: 2,
    }
  );
  return { items: res.data };
}

async function unEquipItem(
  param: equipItemRequest
): Promise<equipItemResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Items/Unequip`,
    {
      ...param,
    }
  );
  return { item: res.data, param };
}

async function getList(): Promise<Item[]> {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/Items/GetList`);
  return res.data;
}

export default function useItems(isAuthenticated: boolean) {
  const queryClient = useQueryClient();

  const add = useMutation(createItem, {
    onSuccess: (addedItem) => {
      queryClient.setQueryData(
        "items",
        (currentItems: Array<Item> | undefined) => {
          if (currentItems === undefined) return [];
          queryClient.invalidateQueries("item");
          return [...currentItems, addedItem.item];
        }
      );
    },
  });

  const equip = useMutation(equipItem, {
    onSuccess: (item) => {
      queryClient.setQueryData("itemsEquipped", () => {
        queryClient.invalidateQueries("group");
        queryClient.invalidateQueries("item");
        queryClient.invalidateQueries("heroes");
        return item.item;
      });
    },
  });

  const unequip = useMutation(unEquipItem, {
    onSuccess: (item) => {
      queryClient.setQueryData("itemsUnquipped", () => {
        queryClient.invalidateQueries("group");
        queryClient.invalidateQueries("item");
        queryClient.invalidateQueries("heroes");
        return item.item;
      });
    },
  });

  const scrapAllCommon = useMutation(scrapAllCommonItems, {
    onSuccess: (items) => {
      queryClient.invalidateQueries("item");
    },
  });

  const scrapAllRare = useMutation(scrapAllRareItems, {
    onSuccess: (items) => {
      queryClient.invalidateQueries("item");
    },
  });

  const scrapAllEpic = useMutation(scrapAllEpicItems, {
    onSuccess: (items) => {
      queryClient.invalidateQueries("item");
    },
  });

  const getQuery = useQuery(["item", "list"], getList, {
    enabled: isAuthenticated,
  });

  return {
    add,
    equip,
    unequip,
    scrapAllCommon,
    scrapAllRare,
    scrapAllEpic,
    getQuery,
  };
}
