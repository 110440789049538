import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { Group } from "../Models/Group";
import { GroupWithInstance } from "../Models/GroupWithInstance";

export type AssignHeroRequest = {
  heroId: number;
  groupId: number;
};

export type AssignHeroResponse = {
  data: Group;
  param: AssignHeroRequest;
};

async function get(): Promise<GroupWithInstance[]> {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Groups/GetList`
  );
  return res.data;
}

async function assignHero(
  param: AssignHeroRequest
): Promise<AssignHeroResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Groups/AssignHero`,
    { ...param }
  );
  return { data: res.data, param };
}

async function unassignHero(
  param: AssignHeroRequest
): Promise<AssignHeroResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Groups/UnassignHero`,
    {
      ...param,
    }
  );
  return { data: res.data, param };
}

export default function useGroups(isAuthenticated: boolean) {
  const queryClient = useQueryClient();

  const assign = useMutation(assignHero, {
    onSuccess: (resp) => {
      queryClient.setQueryData(["group", "assignHeroToGroup"], () => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["group", "list"]);
        queryClient.invalidateQueries(["heroes", "list"]);
        return resp;
      });
    },
  });

  const unassing = useMutation(unassignHero, {
    onSuccess: (resp) => {
      queryClient.setQueryData(["group", "unassingHeroToGroup"], () => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["group", "list"]);
        queryClient.invalidateQueries(["heroes", "list"]);
        return resp;
      });
    },
  });

  const getQuery = useQuery(["group", "list"], get, {
    enabled: isAuthenticated,
  });

  return { assign, unassing, getQuery };
}
