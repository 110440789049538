import React from "react";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import UserList from "./Components/User/UserList";
import "./tailwind.output.css";
import "./index.css";
import Header from "./Components/Header";
import UserIndex from "./Components/User";
import HeroIndex from "./Components/Hero";
import Login from "./Components/Login";
import {
  ROUTE_HEROES,
  ROUTE_HOME,
  ROUTE_ITEMS,
  ROUTE_LOGIN,
  ROUTE_PLAY,
  ROUTE_RANKING,
  ROUTE_TERMS,
  ROUTE_UPGRADES,
  ROUTE_USER,
  ROUTE_USERS,
} from "./Utils/routes";
import { UserProvider } from "./Providers/UserProvider";
import PlayIndex from "./Components/Play";
import ItemIndex from "./Components/Item/Index";
import HeroList from "./Components/Hero/HeroList";
import UpgradesIndex from "./Components/Upgrades";
import { LoadingProvider } from "./Providers/LoadingProvider";
import TermsPageIndex from "./Components/TermsPage";
import HomeIndex from "./Components/Home";
import { RewardsProvider } from "./Providers/RewardsProvider";
import RankingIndex from "./Components/Ranking";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RewardsProvider>
        <LoadingProvider>
          <UserProvider>
            <Header />
            <Routes>
              <Route path={ROUTE_HOME} element={<HomeIndex />} />
              <Route path={ROUTE_TERMS} element={<TermsPageIndex />} />
              <Route path={ROUTE_LOGIN} element={<Login />} />
              <Route path={ROUTE_PLAY} element={<PlayIndex />} />
              <Route path={ROUTE_USER} element={<UserIndex />} />
              <Route path={ROUTE_USERS}>
                <Route index element={<UserList />} />
              </Route>
              <Route path={ROUTE_ITEMS} element={<ItemIndex />} />
              <Route path={ROUTE_HEROES} element={<HeroList />} />
              <Route path={ROUTE_UPGRADES} element={<UpgradesIndex />} />
              <Route path={ROUTE_HEROES}>
                <Route path=":userId/:heroId" element={<HeroIndex />} />
              </Route>
              <Route path={ROUTE_RANKING} element={<RankingIndex />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Nothing here!</p>
                  </main>
                }
              />
            </Routes>
            <ReactQueryDevtools initialIsOpen position="top-left" />
          </UserProvider>
        </LoadingProvider>
      </RewardsProvider>
    </QueryClientProvider>
  );
}

export default App;
