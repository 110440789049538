import React from "react";

type ProgressBarProps = {
  percentageValue: number;
  label?: string;
  className?: string;
  bgColorClassName?: string;
  bgGrayClassName?: string;
};

const ProgressBar = ({
  percentageValue,
  label,
  className,
  bgColorClassName = "bg-green-500",
  bgGrayClassName = "bg-gray-800",
}: ProgressBarProps) => {
  var value = percentageValue <= 0 ? 0 : percentageValue;
  return (
    <div className={`${bgGrayClassName} ${className} w-full rounded-full`}>
      <div
        className={`${bgColorClassName} text-xs font-medium text-white text-center p-0.5 leading-none rounded-full`}
        style={{ width: `${value}%` }}
      >
        {value.toFixed(2)}%
      </div>
    </div>
  );
};

export default ProgressBar;
