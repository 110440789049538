import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { InstanceData } from "../Models/InstanceData";

export type checkIdleRequest = {
  groupId: number;
};
export type startIdleRequest = {
  groupId: number;
};
export type stopIdleRequest = {
  groupId: number;
};

export type checkIdleResponse = {
  instanceData: InstanceData;
  param: checkIdleRequest;
};

export type startIdleResponse = {
  instanceData: InstanceData;
  param: startIdleRequest;
};

export type stopIdleResponse = {
  instanceData: InstanceData;
  param: stopIdleRequest;
};

async function checkIdle(param: checkIdleRequest): Promise<checkIdleResponse> {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Instances/CheckIdle?groupId=${param.groupId}`
  );
  return { instanceData: res.data, param };
}

async function startIdle(param: startIdleRequest): Promise<startIdleResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Instances/StartIdle`,
    {
      ...param,
    }
  );
  return { instanceData: res.data, param };
}

async function stopIdlePost(param: stopIdleRequest): Promise<stopIdleResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Instances/StopIdle`,
    {
      ...param,
    }
  );
  return { instanceData: res.data, param };
}

async function stopPortalMissionPost(
  param: stopIdleRequest
): Promise<stopIdleResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Instances/StopPortalMission`,
    {
      ...param,
    }
  );
  return { instanceData: res.data, param };
}

export default function useIdleInstances(isAuthenticated: boolean) {
  const queryClient = useQueryClient();

  const check = useMutation(checkIdle, {
    onSuccess: (resp) => {
      queryClient.setQueryData(
        ["checkIdleInstance", resp.param.groupId],
        () => {
          return resp.instanceData;
        }
      );
    },
  });

  const start = useMutation(startIdle, {
    onSuccess: (resp) => {
      queryClient.setQueryData(
        ["startIdleInstance", resp.param.groupId],
        () => {
          return resp.instanceData;
        }
      );
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["group", "list"]);
    },
  });

  const stopIdle = useMutation(stopIdlePost, {
    onSuccess: (resp) => {
      queryClient.setQueryData(["stopIdleInstance", resp.param.groupId], () => {
        return resp.instanceData;
      });
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["group", "list"]);
      queryClient.invalidateQueries(["item", "list"]);
    },
  });

  const stopPortalMission = useMutation(stopPortalMissionPost, {
    onSuccess: (resp) => {
      queryClient.setQueryData(
        ["stopPortalMissionPost", resp.param.groupId],
        () => {
          return resp.instanceData;
        }
      );
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["group", "list"]);
      queryClient.invalidateQueries(["item", "list"]);
    },
  });

  return { check, start, stopIdle, stopPortalMission };
}
