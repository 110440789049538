import React from "react";

type IconSize = "small" | "normal";

type SpriteMapProps = {
  imageOpacity?: number;
  currencyId: currenciesTypesEnum;
  iconSize?: IconSize;
};

const NORMAL_BACKGROUND_SIZE = 192;
const NORMAL_TILE_SIZE = 32;

const SMALL_BACKGROUND_SIZE = 144;
const SMALL_TILE_SIZE = 24;

const getTileSize = (iconSize?: IconSize) => {
  switch (iconSize) {
    case "small":
      return SMALL_TILE_SIZE;
    case "normal":
      return NORMAL_TILE_SIZE;
    case null:
      return NORMAL_TILE_SIZE;
    default:
      return NORMAL_TILE_SIZE;
  }
};

const getBackgroundSize = (iconSize?: IconSize) => {
  switch (iconSize) {
    case "small":
      return SMALL_BACKGROUND_SIZE;
    case "normal":
      return NORMAL_BACKGROUND_SIZE;
    case null:
      return NORMAL_BACKGROUND_SIZE;
    default:
      return NORMAL_BACKGROUND_SIZE;
  }
};

export enum currenciesTypesEnum {
  SUPER_COMMON_ORB = 0,
  COMMONIZER_ORB = 1,
  RANDOMIZE_RARITY_ORB = 2,
  EPIC_ORB = 3,
  CHAOTIC_ORB = 4,
  UNREAL_ORB = 5,
}

type tileMap = {
  tileX: number;
  tileY: number;
};

export const currenciesSpriteMappingTiles: Record<
  currenciesTypesEnum,
  tileMap
> = {
  [currenciesTypesEnum.CHAOTIC_ORB]: { tileX: 0, tileY: 0 },
  [currenciesTypesEnum.COMMONIZER_ORB]: { tileX: 1, tileY: 1 },
  [currenciesTypesEnum.SUPER_COMMON_ORB]: { tileX: 2, tileY: 2 },
  [currenciesTypesEnum.RANDOMIZE_RARITY_ORB]: { tileX: 3, tileY: 3 },
  [currenciesTypesEnum.EPIC_ORB]: { tileX: 4, tileY: 4 },
  [currenciesTypesEnum.UNREAL_ORB]: { tileX: 5, tileY: 5 },
};

const spriteFile = "./sprites/currency.png";

const CurrenciesSpriteMap = ({
  currencyId,
  imageOpacity = 1,
  iconSize = "normal",
}: SpriteMapProps) => {
  const tileMap = currenciesSpriteMappingTiles[currencyId];
  const tileSize = getTileSize(iconSize);
  const backgroundSize = getBackgroundSize(iconSize);

  if (!tileMap) return <></>;
  return (
    <div
      className="aspect-square"
      style={{
        opacity: imageOpacity,
        background: `url(${spriteFile})
        -${tileMap.tileX * tileSize}px
        -${tileMap.tileY * tileSize}px`,
        width: `${tileSize}px`,
        backgroundSize: `${backgroundSize}px`,
      }}
    />
  );
};

export default CurrenciesSpriteMap;
