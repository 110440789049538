import { useState, useEffect, useCallback } from "react";

const useCountdown = (endTime: Date | undefined): number | null => {
  const calculateTimeLeft = useCallback((): number | null => {
    if (!endTime) return null;
    const dateEndTime = new Date(endTime);

    const d1 = new Date();
    const nowInUtc = new Date(
      d1.getUTCFullYear(),
      d1.getUTCMonth(),
      d1.getUTCDate(),
      d1.getUTCHours(),
      d1.getUTCMinutes(),
      d1.getUTCSeconds()
    );

    const difference = Math.ceil(
      (dateEndTime.getTime() - nowInUtc.getTime()) / 1000
    );
    console.log("useCountdown difference", difference);
    return Math.max(difference, 0);
  }, [endTime]);

  const [timeLeft, setTimeLeft] = useState<number | null>(calculateTimeLeft());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [calculateTimeLeft, endTime]);

  return timeLeft;
};

export default useCountdown;
