import { Color } from "./Color";

const AllRarityColors = [
  "bg-scrds-magenta",
  "bg-scrds-crimson",
  "bg-scrds-orange",
  "bg-scrds-gold",
  "bg-scrds-green",
  "bg-scrds-blue",
];

const AllRarityBorderColors = [
  "border-scrds-magenta",
  "border-scrds-crimson",
  "border-scrds-orange",
  "border-scrds-gold",
  "border-scrds-green",
  "border-scrds-blue",
];

const AllRarityTextColors = [
  "text-scrds-black",
  "text-scrds-magenta",
  "text-scrds-crimson",
  "text-scrds-orange",
  "text-scrds-gold",
  "text-scrds-green",
  "text-scrds-blue",
];

export function rgbToHex(r: number, g: number, b: number): string {
  function componentToHex(c: number): string {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  r = Math.min(Math.max(0, r), 255);
  g = Math.min(Math.max(0, g), 255);
  b = Math.min(Math.max(0, b), 255);

  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function convertRarityColorToHex(color: Color): string {
  return rgbToHex(color.r, color.g, color.b);
}

export type Rarity = {
  id: number;
  name: string;
  color: Color;
  suffixNum: number;
  prefixNum: number;
  affixSlotsNum: number;
};
