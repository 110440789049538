import { useQuery } from "react-query";
import axios from "axios";
import { MissionThreatType } from "../Models/MissionThreatType";
import { HeroSkillsType } from "../Models/HeroSkillsType";

async function getMissionThreatTypes(): Promise<MissionThreatType[]> {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Enemies/MissionThreatTypes`
  );
  return res.data;
}

async function getHeroSkillsTypes(): Promise<HeroSkillsType[]> {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Enemies/HeroSkillsTypes`
  );
  return res.data;
}

export default function useEnemies(isAuthenticated: boolean) {
  const getMissionThreatTypesQuery = useQuery(
    ["misstionThreatTypes"],
    getMissionThreatTypes,
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    }
  );
  const getHeroSkillsTypesQuery = useQuery(
    ["heroSkillsTypes"],
    getHeroSkillsTypes,
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
    }
  );

  return { getMissionThreatTypesQuery, getHeroSkillsTypesQuery };
}
