import { Hero } from "../../Models/Hero";
import ItemIcon from "../Item/ItemIcon";
import { useCallback, useContext, useEffect, useState } from "react";
import useItems, { equipItemRequest } from "../../Hooks/useItems";
import { UserContext } from "../../Providers/UserProvider";
import { compareItemRarity } from "../../Utils/compareRarity";
import { Item } from "../../Models/Item";
import useUser from "../../Hooks/useUser";
import { LoadingContext } from "../../Providers/LoadingProvider";

type HeroEquipItemsCardProps = {
  hero: Hero;
  blockEquipUnequip?: boolean;
};

const HeroEquipItemsCard = ({
  hero,
  blockEquipUnequip,
}: HeroEquipItemsCardProps) => {
  const { isLoading, setOverlayIsLoading } = useContext(LoadingContext);
  const { isAuthenticated } = useContext(UserContext);
  const { data: user } = useUser(isAuthenticated);
  const {
    getQuery: { data: itemsList, isFetching: isFetchingItemsList },
    equip: mutateEquipItem,
    unequip: mutateUnequipItem,
  } = useItems(isAuthenticated);
  const [sortedItems, setSortedItems] = useState<Array<Item>>([]);

  useEffect(() => {
    setOverlayIsLoading(mutateEquipItem.isLoading);
  }, [mutateEquipItem.isLoading, setOverlayIsLoading]);
  useEffect(() => {
    setOverlayIsLoading(mutateUnequipItem.isLoading);
  }, [mutateUnequipItem.isLoading, setOverlayIsLoading]);

  const onClickEquipeItem = useCallback(
    (item: Item | undefined) => {
      if (!user || !item || blockEquipUnequip) return;
      const equipRequest: equipItemRequest = {
        userId: user.id,
        heroId: hero.id,
        itemId: item.id,
      };
      mutateEquipItem.mutate(equipRequest);
    },
    [blockEquipUnequip, hero.id, mutateEquipItem, user]
  );

  const onClickUnequipeItem = useCallback(
    (item: Item | undefined) => {
      if (!user || !item || blockEquipUnequip) return;
      const equipRequest: equipItemRequest = {
        userId: user.id,
        heroId: hero.id,
        itemId: item.id,
      };
      mutateUnequipItem.mutate(equipRequest);
    },
    [blockEquipUnequip, hero.id, mutateUnequipItem, user]
  );

  useEffect(() => {
    if (!itemsList) return;
    setSortedItems(
      itemsList
        .filter(
          (item) =>
            !item.isEquipped &&
            !item.stackable &&
            item.equippedByClass.includes(hero.heroClassId)
        )
        .sort(compareItemRarity)
    );
  }, [hero.heroClassId, itemsList]);

  return (
    <>
      <div className="w-full text-left text-white text-sm">
        Equipped Items{" "}
        <span className="text-gray-400 text-xs">
          - {blockEquipUnequip ? "Hero is busy" : "Click to unequip"}{" "}
        </span>
      </div>
      <div className={`w-full min-w-64 grid grid-cols-5 p-1 rounded-md`}>
        {Array.from({ length: 10 }, (_, i) => i).map((i) => {
          const item = hero.equippedItems ? hero.equippedItems[i] : undefined;
          return (
            <div
              key={`item-unequip-${i}-${item ? item.id : "0"}`}
              onClick={(e) => {
                onClickUnequipeItem(item);
                e.stopPropagation();
                e.preventDefault();
                e.nativeEvent.stopPropagation();
                e.nativeEvent.preventDefault();
              }}
            >
              <ItemIcon
                key={`itemicongrid-${i}-${item ? item.id : "0"}`}
                item={item}
                index={i}
                isItemSlotLocked={i >= hero.maxEquipsNumber}
              />
            </div>
          );
        })}
      </div>
      <div className="w-full text-left text-white text-sm">
        Available Items{" "}
        <span className="text-gray-400 text-xs">
          - {blockEquipUnequip ? "Hero is busy" : "Click to equip"}
        </span>
      </div>
      <div className={`w-full min-w-64 grid grid-cols-5 p-1 rounded-md`}>
        {Array.from(
          { length: sortedItems.length > 10 ? sortedItems.length : 10 },
          (_, i) => i
        ).map((i) => {
          const item = sortedItems ? sortedItems[i] : undefined;
          return (
            <div
              key={`item-${i}-${item ? item.id : "0"}`}
              onClick={(e) => {
                onClickEquipeItem(item);
                e.stopPropagation();
                e.preventDefault();
                e.nativeEvent.stopPropagation();
                e.nativeEvent.preventDefault();
              }}
            >
              <ItemIcon
                key={`itemicongrid-${i}-${item ? item.id : "0"}`}
                item={item}
                index={i}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default HeroEquipItemsCard;
