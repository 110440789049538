import React from 'react';

type SpriteMapProps = {
  heroClass: heroClassesEnum;
};

const TILE_SIZE = 48;

export enum heroClassesEnum {
  KNIGHT = 1,
  RANGER = 2,
  WIZARD = 3
}

type tileMap = {
  tileX: number;
  tileY: number;
};

const heroesMap: Record<heroClassesEnum, tileMap> = {
  [heroClassesEnum.KNIGHT]: { tileX: 0, tileY: 0 },
  [heroClassesEnum.RANGER]: { tileX: 2, tileY: 2 },
  [heroClassesEnum.WIZARD]: { tileX: 1, tileY: 1 }
};

const spriteFile = './sprites/heroes_icons_centered.png';

const HeroesSpriteMap = ({ heroClass }: SpriteMapProps) => {
  return (
    <div
      className="aspect-square"
      style={{
        background: `url(${spriteFile})
        -${heroesMap[heroClass].tileX * TILE_SIZE}px
        -${heroesMap[heroClass].tileY * TILE_SIZE}px`,
        width: `${TILE_SIZE}px`
      }}
    />
  );
};

export default HeroesSpriteMap;
