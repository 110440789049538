import React, { FC, useState } from "react";
import LoadingOverlay from "../../Components/Basics/LoadingOverlay";

interface Props {
  children: React.ReactNode;
}

export type LoadingContextType = {
  isLoading: boolean;
  setOverlayIsLoading: (isLoading: boolean) => void;
};

export const LoadingContext = React.createContext<LoadingContextType>({
  isLoading: false,
  setOverlayIsLoading: () => {},
});

export const LoadingProvider: FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadingContext: LoadingContextType = {
    isLoading,
    setOverlayIsLoading: setIsLoading,
  };

  return (
    <LoadingContext.Provider value={loadingContext}>
      <LoadingOverlay />
      {children}
    </LoadingContext.Provider>
  );
};
