import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useGroups from "../../Hooks/useGroups";
import useHeroes from "../../Hooks/useHeroes";
import useIdleInstances from "../../Hooks/useIdleInstances";
import useInstances from "../../Hooks/useInstances";
import useItems from "../../Hooks/useItems";
import useUser from "../../Hooks/useUser";
import { InstanceData } from "../../Models/InstanceData";
import { Item } from "../../Models/Item";
import { UserContext } from "../../Providers/UserProvider";
import {
  compareHeroExp,
  compareHeroRarity,
  compareItemRarity,
} from "../../Utils/compareRarity";
import { ROUTE_LOGIN } from "../../Utils/routes";
import LoadingButton from "../Basics/LoadingButton";
import Modal from "../Basics/Modal";
import Page from "../Basics/Page";
import Board from "../Board";
import GroupList from "../Group/GroupList";
import HeroSquareIcon from "../Hero/HeroSquareIcon";
import ItemGrid from "../Item/ItemGrid";

const UserIndex = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useContext(UserContext);
  const params = useParams();
  const { data: user, isFetching } = useUser(isAuthenticated);
  const {
    getQuery: { data: groupsWithInstance, isFetching: isFetchingGroup },
  } = useGroups(isAuthenticated);
  const { add: addHero } = useHeroes(isAuthenticated);
  const {
    add: addItem,
    scrapAllCommon,
    scrapAllRare,
    scrapAllEpic,
    getQuery: { data: itemsList, isFetching: isFetchingItemsList },
  } = useItems(isAuthenticated);

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate(ROUTE_LOGIN);
  //   }
  // }, [isAuthenticated]);

  const { groupInstance: playGroupInstance } = useInstances(isAuthenticated);
  const { check: checkIdleInstance } = useIdleInstances(isAuthenticated);

  const [instanceData, setInstanceData] = useState<InstanceData | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [sortedItems, setSortedItems] = useState<Array<Item>>([]);

  const onClickAddHero = useCallback(() => {
    if (!user) return;
    addHero.mutate(user.id);
  }, [user, addHero]);

  const onClickItemHero = useCallback(() => {
    if (!user) return;
    addItem.mutate({ userId: user.id, iLevel: 1 });
  }, [user, addItem]);

  const onClickScrapCommon = useCallback(() => {
    if (!user) return;
    scrapAllCommon.mutate();
  }, [user, scrapAllCommon]);

  const onClickScrapRare = useCallback(() => {
    if (!user) return;
    scrapAllRare.mutate();
  }, [user, scrapAllRare]);

  const onClickScrapEpic = useCallback(() => {
    if (!user) return;
    scrapAllEpic.mutate();
  }, [user, scrapAllEpic]);

  const onPlayGroupInstance = useCallback(async () => {
    if (!user || !user.groups || user.groups.length === 0) return;
    const instData = await playGroupInstance.mutateAsync({
      userId: user.id,
      instanceId: 1,
    });

    if (instData && instData.instanceData) {
      setInstanceData(instData.instanceData);
      setIsModalOpen(true);
    }
  }, [user, playGroupInstance]);

  useEffect(() => {
    if (!itemsList) return;
    setSortedItems(itemsList.sort(compareItemRarity));
  }, [params.heroId, itemsList]);

  if (!user) return <></>;

  return (
    <Page name={user.username}>
      <div className="flex w-full grid-cols-2">
        <div className="w-1/2 p-2">
          <div className="w-full pb-12 flex">
            <h1 className="text-2xl font-bold text-gray-300 w-full">Group</h1>{" "}
            {groupsWithInstance &&
            groupsWithInstance.length > 0 &&
            groupsWithInstance[0].heroes &&
            groupsWithInstance[0].heroes.length > 0 ? (
              <span className="pl-2 text-xs">
                {instanceData ? (
                  <Modal
                    isOpen={isModalOpen}
                    onClose={() => {
                      setIsModalOpen(false);
                      setInstanceData(undefined);
                    }}
                  >
                    <Board instanceData={instanceData} />
                  </Modal>
                ) : null}
              </span>
            ) : null}
          </div>

          <div className="w-full flex">
            <GroupList
              heroList={user.heroes
                .sort(compareHeroRarity)
                .sort(compareHeroExp)}
              groups={groupsWithInstance}
            />
          </div>
          <div className="w-full pb-12 flex mt-12">
            <h1 className="text-2xl font-bold text-gray-300">Heroes</h1>{" "}
            <span className="pl-2 text-xs">
              <LoadingButton
                text="Add Hero"
                isLoading={addHero.isLoading}
                onClick={onClickAddHero}
              />
            </span>
          </div>
          <div className="w-full flex flex-wrap">
            {user.heroes
              .sort(compareHeroRarity)
              .sort(compareHeroExp)
              .map((hero) => (
                <Link to={`/heroes/${user.id}/${hero.id}`} key={hero.id}>
                  <HeroSquareIcon
                    key={`hero-square-user-${hero.id}`}
                    hero={hero}
                  />
                </Link>
              ))}
          </div>
        </div>
        <div className="w-1/2 p-2">
          <div className="w-full pb-8 flex">
            <h1 className="text-2xl font-bold text-gray-300">Items</h1>
            <span className="pl-2 text-xs">
              <LoadingButton
                text="Add Item"
                isLoading={addItem.isLoading}
                onClick={onClickItemHero}
              />
            </span>
          </div>
          <div className="w-full pb-2 flex">
            <span className="text-xs">
              <LoadingButton
                className="border-scrds-blue"
                text="Scrap Common Items"
                isLoading={scrapAllCommon.isLoading}
                onClick={onClickScrapCommon}
              />
            </span>
            <span className="pl-2 text-xs">
              <LoadingButton
                className="border-scrds-green"
                text="Scrap Rare Items"
                isLoading={scrapAllRare.isLoading}
                onClick={onClickScrapRare}
              />
            </span>
            <span className="pl-2 text-xs">
              <LoadingButton
                className="border-scrds-magenta"
                text="Scrap Epic Items"
                isLoading={scrapAllEpic.isLoading}
                onClick={onClickScrapEpic}
              />
            </span>
          </div>
          <div className="w-full">
            <ItemGrid items={sortedItems} userId={user.id} />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default UserIndex;
