import React, { useEffect, useState } from 'react';
import { Actor } from '../Models/Actor';
import { InstanceData } from '../Models/InstanceData';
import { TickActorState } from '../Models/TickActorState';
import PureCanvas from './PureCanvas';

type BoardProps = {
  instanceData: InstanceData;
};

type DamageData = {
  actorX: number;
  actorY: number;
  value: number;
};

export default function Board({ instanceData }: BoardProps) {
  const [damages, setDamages] = useState<DamageData[]>();
  const [actors, setActors] = useState<Record<string, Actor>>();
  const [tickStates, setTickStates] = useState<TickActorState[][]>();
  const [tickState, setTickState] = useState<TickActorState[]>();
  const [tick, setTick] = useState(0);
  const tickRate = 2; // hertz
  const animationTime = 15;

  const canWidth = 500;
  const canHeight = 500;

  const resizeFactor = canHeight / 1000;

  useEffect(() => {
    const tempActors: Record<string, Actor> = {};
    instanceData.Actors.forEach((actor: Actor) => {
      tempActors[actor.Guid] = actor;
    });
    setActors(tempActors);

    setTickStates(instanceData.Ticks);
  }, [instanceData]);

  useEffect(() => {
    if (!tickStates) return;

    const tickStateActorState = tickStates[tick];
    if (!tickStateActorState) {
      setDamages([]);
      return;
    }

    let tempDamages: DamageData[] = [];
    tickStateActorState.forEach((actorState) => {
      if (actorState.DamageTaken > 0) {
        tempDamages = [
          ...tempDamages,
          {
            actorX: actorState.X,
            actorY: actorState.Y,
            value: actorState.DamageTaken
          }
        ];
      }
    });
    setDamages(tempDamages);

    setTickState(tickStateActorState);

    setTimeout(() => {
      setTick(tick + 1);
    }, 1000 / tickRate);
  }, [tick, tickStates]);

  const drawHealthBar = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    MaxHealth: number,
    CurrentHealth: number
  ) => {
    const offset = 30 * resizeFactor;
    const beginX = x - width / 2;
    const beginY = y - height / 2;
    const healthPercentage = CurrentHealth / MaxHealth;

    ctx.fillStyle = '#000';
    ctx.beginPath();
    ctx.fillRect(beginX, beginY + offset, width, height);
    ctx.fill();

    ctx.fillStyle = 'red';
    ctx.beginPath();
    ctx.fillRect(beginX, beginY + offset, width * healthPercentage, height);
    ctx.fill();

    ctx.font = '10px serif';
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.fillText(`${CurrentHealth}/${MaxHealth}`, x, beginY + offset + 20 * resizeFactor);
  };

  const drawActor = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    name: string,
    MaxHealth: number,
    CurrentHealth: number
  ) => {
    ctx.fillStyle = '#fff';
    ctx.beginPath();
    ctx.arc(x, y, 20 * resizeFactor, 0, 2 * Math.PI);
    ctx.fill();

    ctx.font = '12px serif';
    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.fillText(name, x, y - 30 * resizeFactor);

    drawHealthBar(ctx, x, y, 40 * resizeFactor, 10 * resizeFactor, MaxHealth, CurrentHealth);
  };

  const drawDamage = (ctx: CanvasRenderingContext2D, damage: DamageData, frameCount: number) => {
    if (frameCount > animationTime) return;

    const speed = 2;
    const movedX = damage.actorX * 100 * resizeFactor + speed * frameCount * 0.6;
    const movedY = damage.actorY * 100 * resizeFactor - speed * frameCount;

    ctx.font = '600 15px serif';
    ctx.fillStyle = 'red';
    ctx.textAlign = 'center';
    ctx.fillText(damage.value.toString(), movedX, movedY);
  };

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    if (!tickStates || !actors || !tickState) return;

    ctx.fillStyle = '#333';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    tickState.forEach((tickActorState: TickActorState) => {
      const actor: Actor = actors[tickActorState.ActorGuid];
      drawActor(
        ctx,
        tickActorState.X * 100 * resizeFactor,
        tickActorState.Y * 100 * resizeFactor,
        actor.Name,
        actor.MaxHealth,
        tickActorState.Health
      );
    });

    damages?.forEach((damage) => {
      drawDamage(ctx, damage, frameCount);
    });

    // ctx.fillStyle = '#000000';
    // ctx.beginPath();
    // ctx.arc(50, 100, 20 * Math.sin(frameCount * 0.05) ** 2, 0, 2 * Math.PI);
    // ctx.fill();
  };

  if (!instanceData) return <></>;
  return <PureCanvas draw={draw} canWidth={canWidth} canHeight={canHeight} />;
}
