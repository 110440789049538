import axios from "axios";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { User } from "../Models/User";
import { ROUTE_HOME, ROUTE_LOGIN } from "../Utils/routes";
import jwtInterceptor from "../_helpers/jwtInterceptor";

export default function useIdentity(
  setIsAuthenticated: (isAuthenticated: boolean) => void
) {
  const queryClient = useQueryClient();
  const [authToken, setAuthToken] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  async function getTokenFromBackend(
    accessToken: string
  ): Promise<string | null> {
    const url = `${process.env.REACT_APP_API_URL}/Identity/Login`;
    const res = await axios.post(url, { access_token: accessToken });

    if (res && res.data && res.data.token) {
      return res.data.token;
    } else {
      return null;
    }
  }

  async function login(accessToken: string): Promise<string | null> {
    setIsLoading(true);

    try {
      const token = await getTokenFromBackend(accessToken);

      setIsLoading(false);
      if (token) {
        jwtInterceptor(token, setIsAuthenticated);
        setAuthToken(token);
        queryClient.invalidateQueries(["user"]);
        navigate(ROUTE_HOME);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        setIsLoading(false);
        return null;
      }

      return token;
    } catch (error) {
      setIsAuthenticated(false);
      setIsLoading(false);
      return null;
    }
  }

  return { login, authToken, isLoading };
}
