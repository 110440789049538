import React from "react";
import FooterMenu from "./FooterMenu";

interface PageProps extends React.ComponentPropsWithoutRef<"div"> {
  name: string;
  childrenClassName?: string;
  backgroundSrc?: string;
}

const backgroundImg = "./sprites/cover.jpg";

const Page = ({
  children,
  name,
  childrenClassName,
  backgroundSrc,
}: PageProps) => {
  return (
    <>
      {/* <header className="bg-gray-700 shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-200">{name}</h1>
        </div>
      </header> */}
      <div
        className="flex flex-grow p-3 sm:p-3 lg:p-4 overflow-y-auto w-full flex-col items-center"
        style={{
          background: `url(${backgroundSrc ? backgroundSrc : backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className={`flex w-full max-w-md p-3 sm:p-3 lg:p-4 ${childrenClassName} bg-black/75`}
        >
          {children}
        </div>
      </div>
      <FooterMenu />
    </>
  );
};

export default Page;
