import React from "react";
import { Item } from "../../Models/Item";
import AffixCardShower from "../Affix/AffixCardShower";
import Modal from "../Basics/Modal";
import LoadingButton from "../Basics/LoadingButton";

type ItemModalProps = {
  item: Item;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

const ItemModal = ({ item, isModalOpen, setIsModalOpen }: ItemModalProps) => {
  return (
    <Modal
      zIndex={50}
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="p-4 border-gray-700">
        <p
          className={`text-sm font-bold pb-1 text-scrds-${
            item.rarity ? item.rarity.color.name.toLowerCase() : "black"
          }`}
        >
          {item.name}
          <span className="text-sm text-gray-400 font-light">
            {" "}
            {item.rarity ? item.rarity.name : ""}
          </span>
        </p>
        <p className="text-sm text-gray-300 font-light">
          iLevel: {item.iLevel}
        </p>

        <AffixCardShower
          className="py-2 w-full"
          itemId={item.id}
          implicitJson={item.implicitJson}
          affixesJson={item.affixesJson}
        />
        <div className="w-full pt-2 flex">
          <span className="text-xs">
            <LoadingButton
              isLoading={false}
              className="border-gray-600"
              text="Close"
              onClick={() => {
                setIsModalOpen(false);
              }}
            />
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ItemModal;
