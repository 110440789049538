import React from "react";
import ReactDOM from "react-dom";
import "./tailwind.output.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface Window {
    FB: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="bg-gray-600 h-full flex flex-col w-full">
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
