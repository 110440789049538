import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { InstanceData } from "../Models/InstanceData";

export type playHeroEnemyRequest = {
  userId: number;
  heroId: number;
  enemyType: number;
};

export type playHeroInstanceRequest = {
  userId: number;
  heroId: number;
  instanceId: number;
};

export type playGroupInstanceRequest = {
  userId: number;
  instanceId: number;
};

export type playHeroEnemyResponse = {
  instanceData: InstanceData;
  param: playHeroEnemyRequest;
};

export type playHeroInstanceResponse = {
  instanceData: InstanceData;
  param: playHeroInstanceRequest;
};

export type playGroupInstanceResponse = {
  instanceData: InstanceData;
  param: playGroupInstanceRequest;
};

async function playHeroEnemy(
  param: playHeroEnemyRequest
): Promise<playHeroEnemyResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Instances/PlayHeroEnemy`,
    { ...param }
  );
  return { instanceData: res.data, param };
}

async function playHeroInstance(
  param: playHeroInstanceRequest
): Promise<playHeroInstanceResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Instances/PlayHeroInstance`,
    { ...param }
  );
  return { instanceData: res.data, param };
}

async function playGroupInstance(
  param: playGroupInstanceRequest
): Promise<playGroupInstanceResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Instances/PlayGroupInstance`,
    { ...param }
  );
  return { instanceData: res.data, param };
}

export default function useInstances(isAuthenticated: boolean) {
  const queryClient = useQueryClient();

  const heroEnemy = useMutation(playHeroEnemy, {
    onSuccess: (resp) => {
      queryClient.setQueryData("instancePlayHeroEnemy", () => {
        return resp.instanceData;
      });
    },
  });

  const heroInstance = useMutation(playHeroInstance, {
    onSuccess: (resp) => {
      queryClient.setQueryData("instancePlayHeroInstance", () => {
        return resp.instanceData;
      });
    },
  });

  const groupInstance = useMutation(playGroupInstance, {
    onSuccess: (resp) => {
      queryClient.setQueryData("instancePlayGroupInstance", () => {
        queryClient.invalidateQueries(["user", resp.param.userId]);
        queryClient.invalidateQueries(["users"]);
        return resp.instanceData;
      });
    },
  });

  return { heroEnemy, heroInstance, groupInstance };
}
