import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Outlet, useParams } from "react-router-dom";
import useInstances from "../../Hooks/useInstances";
import useUser from "../../Hooks/useUser";
import { Hero } from "../../Models/Hero";
import { InstanceData } from "../../Models/InstanceData";
import { Item } from "../../Models/Item";
import { UserContext } from "../../Providers/UserProvider";
import { compareItemRarity } from "../../Utils/compareRarity";
import LoadingButton from "../Basics/LoadingButton";
import Modal from "../Basics/Modal";
import Page from "../Basics/Page";
import Board from "../Board";
import ItemGrid from "../Item/ItemGrid";
import HeroCard from "./HeroCard";
import HeroEquips from "./HeroEquips";

const HeroIndex = () => {
  const { isAuthenticated } = useContext(UserContext);
  const params = useParams();
  const { status, data: user, error, isFetching } = useUser(isAuthenticated);

  const { heroEnemy: playHeroEnemy, heroInstance: playHeroInstance } =
    useInstances(isAuthenticated);
  const [sortedItems, setSortedItems] = useState<Array<Item>>([]);
  const [hero, setHero] = useState<Hero | undefined>(undefined);
  const [instanceData, setInstanceData] = useState<InstanceData | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!user || !user.items || !user.heroes) return;
    setSortedItems(user.items.sort(compareItemRarity));

    const heroes = user.heroes.filter((h) => h.id === Number(params.heroId));
    if (heroes.length < 1) return;
    setHero(heroes[0]);
  }, [params.heroId, user]);

  const onPlayHeroEnemy = useCallback(async () => {
    if (!user || !hero) return;
    const data = await playHeroEnemy.mutateAsync({
      userId: user.id,
      heroId: hero.id,
      enemyType: 1,
    });

    if (data && data.instanceData) {
      setInstanceData(data.instanceData);
      setIsModalOpen(true);
    }
  }, [user, hero, playHeroEnemy]);

  const onPlayHeroInstance = useCallback(async () => {
    if (!user || !hero) return;
    const data = await playHeroInstance.mutateAsync({
      userId: user.id,
      heroId: hero.id,
      instanceId: 1,
    });

    if (data && data.instanceData) {
      setInstanceData(data.instanceData);
      setIsModalOpen(true);
    }
  }, [user, hero, playHeroInstance]);

  if (!user || !hero) return <></>;

  return (
    <Page name={hero.heroClass.name}>
      <div className="w-full">
        <div className="w-full">
          {status === "success" ? (
            <div className="flex w-full grid-cols-2">
              <div className="w-1/2 p-2 pt-14">
                <HeroCard
                  hero={hero}
                  userId={Number(params.userId)}
                  sortedItems={sortedItems}
                  allowDragItems
                  showItemsTooltip
                  group={user.groups[0]}
                />
                <div className="w-full p-2 pt-14">
                  <LoadingButton
                    text="Play Enemy"
                    isLoading={playHeroEnemy.isLoading}
                    onClick={onPlayHeroEnemy}
                  />
                  <LoadingButton
                    text="Play Instance"
                    isLoading={playHeroInstance.isLoading}
                    onClick={onPlayHeroInstance}
                  />
                  {instanceData ? (
                    <Modal
                      isOpen={isModalOpen}
                      onClose={() => {
                        setIsModalOpen(false);
                        setInstanceData(undefined);
                      }}
                    >
                      <Board instanceData={instanceData} />
                    </Modal>
                  ) : null}
                </div>
                <HeroEquips hero={hero} showItemsTooltip />
              </div>
              <div className="w-1/2 p-2">
                <h1 className="text-2xl font-bold text-gray-300 mb-4">Items</h1>
                <ItemGrid
                  items={sortedItems}
                  equipedItems={hero.equippedItems}
                  userId={Number(params.userId)}
                  heroId={hero.id}
                  allowDragItems
                />
              </div>
            </div>
          ) : null}
        </div>
        <div className="w-full">
          {status === "error" ? (
            <span>
              <>Error: {error}</>
            </span>
          ) : null}
          {isFetching ? "Loading..." : " "}
        </div>
      </div>
      <Outlet />
    </Page>
  );
};

export default HeroIndex;
