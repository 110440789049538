import { useContext, useState } from "react";
import useUser from "../../Hooks/useUser";
import { UserContext } from "../../Providers/UserProvider";
import { compareHeroExp, compareHeroRarity } from "../../Utils/compareRarity";
import Page from "../Basics/Page";
import HeroGrid from "./HeroGrid";
import { NavLink } from "react-router-dom";
import { ROUTE_UPGRADES } from "../../Utils/routes";
import LoadingButton from "../Basics/LoadingButton";
import PlusOneIcon from "../SVGIcons/PlusOneIcon";
import useHeroes from "../../Hooks/useHeroes";

const HeroList = () => {
  const { isAuthenticated } = useContext(UserContext);
  const {
    getListQuery: { data: heroes, isFetching: isFetchingHeroList },
  } = useHeroes(isAuthenticated);

  if (!heroes) return <></>;

  return (
    <Page name="Heroes">
      <div className="w-full">
        <div className="w-full flex flex-row justify-around mb-3">
          <NavLink to={ROUTE_UPGRADES}>
            <LoadingButton
              icon={<PlusOneIcon className="h-5 w-5" fill="#fff" />}
              text="Get more heroes"
              onClick={() => {
                console.log("take more heroes");
              }}
            />
          </NavLink>
        </div>
        <div className="w-full text-center text-white pb-2">
          Click on Hero icon for more info
        </div>
        <HeroGrid
          heroes={heroes.sort(compareHeroRarity).sort(compareHeroExp)}
        />
      </div>
    </Page>
  );
};

export default HeroList;
