import React, { FC, useCallback, useState } from "react";
import LoadingOverlay from "../../Components/Basics/LoadingOverlay";
import { group } from "console";
import Modal from "../../Components/Basics/Modal";
import HeroSquareIcon from "../../Components/Hero/HeroSquareIcon";
import ItemGrid from "../../Components/Item/ItemGrid";
import { Hero } from "../../Models/Hero";
import { Item } from "../../Models/Item";
import { InstanceData } from "../../Models/InstanceData";
import LoadingButton from "../../Components/Basics/LoadingButton";

interface Props {
  children: React.ReactNode;
}

export type RewardsContextType = {
  addReward: (heroes: Hero[], instanceData: InstanceData) => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

export const RewardsContext = React.createContext<RewardsContextType>({
  addReward: () => {},
  setIsModalOpen: () => {},
});

export type Reward = {
  index: number;
  heroes: Hero[];
  itemsEarned: Item[];
  totalExpEarned: number;
};

export const RewardsProvider: FC<Props> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [rewards, setRewards] = useState<Reward[]>([]);

  const addReward = useCallback(
    (heroes: Hero[], instanceData: InstanceData) => {
      const newReward: Reward = {
        index: 0,
        heroes: heroes,
        itemsEarned: instanceData.itemsEarned,
        totalExpEarned: instanceData.totalExpEarned,
      };

      newReward.index = rewards.length + 1;
      setRewards([...rewards, newReward]);
      setIsModalOpen(true);
    },
    [rewards]
  );

  const closeModal = useCallback(
    (reward: Reward) => {
      const newRewards = rewards.filter((x) => x.index !== reward.index);
      setRewards(newRewards);
      setIsModalOpen(false);
    },
    [rewards]
  );

  const loadingContext: RewardsContextType = {
    addReward,
    setIsModalOpen,
  };

  return (
    <RewardsContext.Provider value={loadingContext}>
      {rewards.map((reward) => {
        return (
          <Modal
            key={`reward-modal-${reward.index}`}
            isOpen={isModalOpen}
            onClose={() => {
              closeModal(reward);
            }}
          >
            <LoadingButton
              text="Close"
              onClick={() => {
                closeModal(reward);
              }}
            />
            <div className="max-w-xs max-h-96 flex flex-col bg-gray-700 m-auto p-6 border-2 border-gray-900 rounded-md">
              <div className="text-lg font-bold text-gray-300 text-center">
                Rewards
              </div>
              <div>
                <div className="w-full grid grid-cols-5 mb-3">
                  {reward.heroes.map((hero) => {
                    return (
                      <div
                        className="w-14"
                        key={`hero-square-${reward.index}-${hero.id}`}
                      >
                        <HeroSquareIcon
                          hero={hero}
                          expEarned={Math.floor(
                            reward.totalExpEarned / reward.heroes.length
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-full h-full overflow-y-auto">
                {reward.itemsEarned ? (
                  <ItemGrid
                    items={reward.itemsEarned}
                    gridSize={reward.itemsEarned.length}
                  />
                ) : null}
              </div>
            </div>
          </Modal>
        );
      })}
      {children}
    </RewardsContext.Provider>
  );
};
