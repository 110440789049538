import { useCallback, useContext } from "react";
import { GroupWithInstance } from "../../Models/GroupWithInstance";
import { Hero } from "../../Models/Hero";
import Modal from "../Basics/Modal";
import HeroSquareIcon from "./HeroSquareIcon";
import useGroups from "../../Hooks/useGroups";
import { UserContext } from "../../Providers/UserProvider";
import LoadingIcon from "../SVGIcons/LoadingIcon";
import RemoveCircleIcon from "../SVGIcons/RemoveCircleIcon";
import LoadingButton from "../Basics/LoadingButton";
import { NavLink } from "react-router-dom";
import { ROUTE_UPGRADES } from "../../Utils/routes";
import PlusOneIcon from "../SVGIcons/PlusOneIcon";
import { LoadingContext } from "../../Providers/LoadingProvider";

type AssignHeroToGroupModalProps = {
  groupId: number;
  group: GroupWithInstance;
  heroList: Hero[];
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
};

const groupListSize = 5;
const listArray: number[] = [];
for (let index = 0; index < groupListSize; index += 1) {
  listArray.push(index);
}

const AssignHeroToGroupModal = ({
  isModalOpen,
  setIsModalOpen,
  heroList,
  group,
  groupId,
}: AssignHeroToGroupModalProps) => {
  const { setOverlayIsLoading } = useContext(LoadingContext);
  const { isAuthenticated } = useContext(UserContext);
  const { unassing: unassignHeroToGroup, assign: assignHeroToGroup } =
    useGroups(isAuthenticated);

  const onAssignHeroToGroup = useCallback(
    async (hero: Hero) => {
      if (!hero || !groupId) return;
      setOverlayIsLoading(true);
      const data = await assignHeroToGroup.mutateAsync({
        heroId: hero.id,
        groupId: groupId,
      });
      setOverlayIsLoading(false);
    },
    [groupId, setOverlayIsLoading, assignHeroToGroup]
  );

  const onUnassignHeroToGroup = useCallback(
    async (hero: Hero) => {
      if (!hero || !groupId) return;

      setOverlayIsLoading(true);
      const data = await unassignHeroToGroup.mutateAsync({
        heroId: hero.id,
        groupId,
      });
      setOverlayIsLoading(false);
    },
    [groupId, setOverlayIsLoading, unassignHeroToGroup]
  );

  const clickToAssignToGroup = useCallback(
    async (hero: Hero) => {
      console.log("clickToAssignToGroup hero", hero, "groupId", groupId);
      if (groupId) {
        await onAssignHeroToGroup(hero);
        return;
      }
      // if ((hero && heroParsed) || isBusy) return;
    },
    [groupId, onAssignHeroToGroup]
  );

  if (!group) return <></>;
  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="w-full bg-gray-700 m-auto p-6 border-2 border-gray-900 rounded-md">
        <div className="text-lg font-bold text-gray-300 text-center">
          Assign Hero To Group
        </div>
        <div className="w-full grid grid-cols-5 mb-3">
          {listArray.map((elem) => {
            const num = Number(elem);
            const hero = group.heroes ? group.heroes[num] : undefined;
            return (
              <div className="relative" key={`hero-square-${group.id}-${num}`}>
                {hero ? (
                  <>
                    <div
                      className="absolute -right-2 -top-2 w-6 aspect-square
              justify-center
              items-center flex
              rounded-full
              bg-gray-900
              z-10"
                    >
                      {unassignHeroToGroup.isLoading ? (
                        <LoadingIcon />
                      ) : (
                        <RemoveCircleIcon
                          onClick={() => onUnassignHeroToGroup(hero)}
                          fill="#fff"
                        />
                      )}
                    </div>
                    {/* <div
                  className="absolute -right-2 -bottom-2 w-6 aspect-square
            justify-center
            items-center flex
            rounded-full
            bg-gray-900
            z-10"
                >
                  <SettingsIcon fill="#fff" />
                </div> */}
                  </>
                ) : null}
                <HeroSquareIcon hero={hero} blockModal />
              </div>
            );
          })}
        </div>
        <div className="w-full text-white text-center mb-2">
          Click on hero to add to group
        </div>
        <div className="w-full grid grid-cols-5 max-h-36 overflow-y-auto">
          {heroList
            .filter((hero) => !hero.groupId)
            .map((hero) => (
              <div
                key={`hero-square-user-wrapper-${hero.id}`}
                id="add-hero"
                onClick={(e) => {
                  clickToAssignToGroup(hero);
                  e.stopPropagation();
                  e.preventDefault();
                  e.nativeEvent.stopPropagation();
                  e.nativeEvent.preventDefault();
                }}
              >
                <HeroSquareIcon
                  key={`hero-square-user-${hero.id}`}
                  hero={hero}
                  blockModal
                />
              </div>
            ))}
        </div>
        <div className="w-full flex flex-row justify-around mt-3">
          <NavLink to={ROUTE_UPGRADES}>
            <LoadingButton
              icon={<PlusOneIcon className="h-5 w-5" fill="#fff" />}
              text="Hero"
              onClick={() => {
                console.log("take more heroes");
              }}
            />
          </NavLink>
        </div>
      </div>
    </Modal>
  );
};

export default AssignHeroToGroupModal;
