import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { InstanceData } from "../Models/InstanceData";

export type startPortalMissionRequest = {
  groupId: number;
  itemIds: number[];
};

export type startIdleResponse = {
  instanceData: InstanceData;
  param: startPortalMissionRequest;
};

async function startPortalMission(
  param: startPortalMissionRequest
): Promise<startIdleResponse> {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/Instances/StartPortalMission`,
    {
      ...param,
    }
  );
  return { instanceData: res.data, param };
}

export default function usePortalMissionInstances(isAuthenticated: boolean) {
  const queryClient = useQueryClient();

  const start = useMutation(startPortalMission, {
    onSuccess: (resp) => {
      queryClient.setQueryData(
        ["startPortalMission", resp.param.groupId, resp.param.itemIds],
        () => {
          return resp.instanceData;
        }
      );
      queryClient.invalidateQueries(["user"]);
      queryClient.invalidateQueries(["group", "list"]);
      queryClient.invalidateQueries(["item", "list"]);
    },
  });

  return { start };
}
