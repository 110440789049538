import React, { useEffect, useState } from "react";
import { ModFromJson } from "../../Models/ModFromJson";
import AffixCardShowerLine from "./AffixCardShowerLine";

type AffixCardShowerProps = {
  implicitJson: string;
  affixesJson: string;
  itemId: number;
  className?: string;
};

const AffixCardShower = ({
  implicitJson,
  affixesJson,
  itemId,
  className,
}: AffixCardShowerProps) => {
  const [affixes, setAffixes] = useState<Array<ModFromJson>>([]);
  const [implicit, setImplicit] = useState<ModFromJson>();

  useEffect(() => {
    if (affixesJson) {
      const obj = JSON.parse(affixesJson);
      setAffixes(obj);
    }
  }, [affixesJson]);

  useEffect(() => {
    if (implicitJson) {
      const obj = JSON.parse(implicitJson);
      setImplicit(obj);
    }
  }, [implicitJson]);

  return (
    <div className={className}>
      {implicit ? (
        <AffixCardShowerLine
          className="text-xs"
          value={implicit.Value}
          text={implicit.Text}
          affixType={implicit.AffixType}
          tags={implicit.ModTags}
          keyString={`${itemId}-${implicit.ModType}`}
        />
      ) : null}
      {affixes?.map((mod) => (
        <div key={`${itemId}-${mod.ModType}`}>
          <AffixCardShowerLine
            className="text-xs"
            value={mod.Value}
            text={mod.Text}
            affixType={mod.AffixType}
            tags={mod.ModTags}
            keyString={`${itemId}-${mod.ModType}`}
          />
        </div>
      ))}
    </div>
  );
};

export default AffixCardShower;
