import Modal from "../Basics/Modal";
import LoadingButton from "../Basics/LoadingButton";
import ProgressBar from "../Basics/ProgressBar";
import { Hero } from "../../Models/Hero";
import HeroSquareIcon from "./HeroSquareIcon";
import HeroEquipItemsCard from "./HeroEquipItemsCard";

type HeroModalProps = {
  hero: Hero;
  isModalOpen: boolean;
  blockEquipUnequip?: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};

const HeroModal = ({
  hero,
  isModalOpen,
  blockEquipUnequip,
  setIsModalOpen,
}: HeroModalProps) => {
  return (
    <Modal
      zIndex={40}
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <div className="w-full flex items-center flex-col p-2">
        <div className="border-gray-700 flex flex-row">
          <div className="w-24">
            <HeroSquareIcon hero={hero} blockModal />
          </div>
          <div className="w-full mb-2 ml-2">
            <div
              className={`text-sm font-bold  text-scrds-${hero.rarity.color.name.toLowerCase()}`}
            >
              {hero.heroClass.name}
              <span className="text-sm text-gray-400 font-light">
                {" "}
                {hero.rarity.name}
              </span>
            </div>
            <span className="text-xs text-gray-400">Lvl. {hero.level}</span>
            <span className="text-xs ml-2 text-gray-400">
              Exp: {hero.experience - hero.previousExp}/
              {hero.nextExp - hero.previousExp}
            </span>
            <ProgressBar
              percentageValue={
                hero.nextExp === 0
                  ? 100
                  : ((hero.experience - hero.previousExp) /
                      (hero.nextExp - hero.previousExp)) *
                    100
              }
              bgColorClassName="bg-orange-400"
              bgGrayClassName="bg-gray-600"
            />
          </div>

          {/* <AffixCardShower
          className="py-2 w-full"
          itemId={item.id}
          implicitJson={item.implicitJson}
          affixesJson={item.affixesJson}
        /> */}
        </div>
        <HeroEquipItemsCard hero={hero} blockEquipUnequip={blockEquipUnequip} />
        <span className="text-xs">
          <LoadingButton
            isLoading={false}
            className="border-gray-600"
            text="Close"
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
        </span>
      </div>
    </Modal>
  );
};

export default HeroModal;
