import React, { useContext, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import useItems, { equipItemRequest } from "../../Hooks/useItems";
import { Item } from "../../Models/Item";
import { UserContext } from "../../Providers/UserProvider";
import { droppedItem } from "../Hero/HeroCard";
import ItemIcon, {
  equipItemIconDragType,
  unequipItemIconDragType,
} from "./ItemIcon";

type ItemGridProps = {
  items: Array<Item>;
  equipedItems?: Array<Item>;
  userId?: number;
  heroId?: number;
  allowDragItems?: boolean;
  gridSize?: number;
  className?: string;
};

const ItemGrid = ({
  items,
  equipedItems,
  userId,
  heroId,
  allowDragItems = false,
  gridSize = 100,
  className = "",
}: ItemGridProps) => {
  const { isAuthenticated } = useContext(UserContext);
  const [grid, setGrid] = useState<Array<number>>([]);

  const completedGridSize = gridSize + (10 - (gridSize % 10));

  useEffect(() => {
    const ar = [];
    for (let i = 0; i < completedGridSize; i += 1) {
      ar.push(i);
    }
    setGrid(ar);
  }, [items]);

  return (
    <div
      className={`${className} w-full shadow-xl grid grid-cols-5 bg-gray-700/50 border-2 border-transparent hover:border-gray-900 p-1 rounded-md`}
    >
      {grid.map((i) => {
        const item = items[i];
        return (
          <ItemIcon
            key={`itemicongrid-${i}-${item ? item.id : "0"}`}
            item={item}
            index={i}
            allowModal
          />
        );
      })}
    </div>
  );
};

export default ItemGrid;
