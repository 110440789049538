export const ROUTE_TERMS = "/privacy-policy";
export const ROUTE_LOGIN = "/login";
export const ROUTE_USERS = "/users";
export const ROUTE_USER = "/user";
export const ROUTE_HOME = "/";
export const ROUTE_PLAY = "/play";
export const ROUTE_HEROES = "/heroes";
export const ROUTE_ITEMS = "/items";
export const ROUTE_UPGRADES = "/upgrades";
export const ROUTE_RANKING = "/ranking";
