import React from "react";
import { FaDiscord } from "react-icons/fa";

const DiscordLink: React.FC = () => {
  return (
    <a
      href="https://discord.gg/3A2Pd8zB78"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center p-2 lg:px-4 lg:py-2 bg-gray-700 text-white rounded-full lg:rounded hover:bg-blue-800 focus:outline-none"
    >
      <FaDiscord className="text-lg lg:mr-2" />{" "}
      <span className="hidden lg:inline">Join us on Discord</span>
    </a>
  );
};

export default DiscordLink;
