import Page from "../Basics/Page";
import useRanking from "../../Hooks/useRanking";

const RankingIndex = () => {
  const {
    getExpRankingQuery: { data: rankingList, isFetching },
  } = useRanking();

  return (
    <Page name="Ranking">
      <div className="w-full flex flex-col text-white">
        <ul className="list-decimal">
          {rankingList?.map((publicUser) => {
            return (
              <li>
                <div className="bg-gray-800 flex text-sm rounded-full focus:outline-none">
                  <img
                    className="h-8 w-8 rounded-full focus:outline-none"
                    src={
                      publicUser.profileImage
                        ? `data:${publicUser.profileImage}`
                        : ""
                    }
                    alt=""
                  />
                  <div className="ml-2 text-white">
                    {publicUser.totalExp} Exp
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Page>
  );
};

export default RankingIndex;
