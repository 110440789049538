import Divider from "../Home/Divider";
import ChangelogListItem from "./ChangelogListItem";

const list = [
  {
    date: "27.07.2023",
    items: [
      {
        title: "[UI] Rewards",
        text: "Simplify rewards screen because of performance reasons.",
      },
      {
        title: "[UI] Loading",
        text: "Added loading feedback in Equip/Unequip, Start Mission and Get Rewards.",
      },
    ],
  },
  {
    date: "26.07.2023",
    items: [
      {
        title: "Optimization",
        text: "Significantly decreased the request time in many cases.",
      },
    ],
  },
  {
    date: "24.07.2023",
    items: [
      {
        title: "Background Images",
        text: "Used AI to generate images that could represent the Sacerdos World. And inserted them as the background. Still testing the feel of it.",
      },
      {
        title: "Currency Display",
        text: "You can see your current amount of all currencies in many screens.",
      },
      {
        title: "Store",
        text: "Now, it is possible to buy 100 Portal Missions at once.",
      },
      {
        title: "Portal Missions",
        text: "'Start All' button was added.",
      },
    ],
  },
  {
    date: "23.07.2023",
    items: [
      {
        title: "[Fix] Scrap",
        text: "Scrapping action is not scrapping the currencies anymore.",
      },
    ],
  },
  {
    date: "22.07.2023",
    items: [
      {
        title: "Rewards",
        text: "A new system to show the rewards received. A more centralized way.",
      },
      {
        title: "Portal Mission",
        text: "Now it is possible to use many Portal Missions at once, you can queue them. You just need to select many Portal Missions when starting them.",
      },
      {
        title: "[Fix] Portal Mission",
        text: "Now the Mission level and rarity are the ones of the item used to generate the mission. ",
      },
    ],
  },
  {
    date: "20.07.2023",
    items: [
      {
        title: "Shop",
        text: "You can buy variable amounts of item at once. In this case, buy 10x Portal Missions.",
      },
    ],
  },
  {
    date: "17.07.2023",
    items: [
      {
        title: "Changelog",
        text: "Added a simple changelog to homepage.",
      },
      {
        title: "[Fix] Equip and Unequip items",
        text: "Fixed an issue where the users could equip and unequip heroes that were busy.",
      },
    ],
  },
  {
    date: "14.07.2023",
    items: [
      {
        title: "Portal Mission",
        text: "Introduced a new type of instance",
      },
      {
        title: "Mission Threats",
        text: "A new mechanic of each Portal Mission having a mission threat",
      },
      {
        title: "Hero Skills",
        text: "Hero skills are implicits in Trinkets",
      },
    ],
  },
];

const ChangelogList = () => {
  return (
    <div className="w-full flex flex-col mt-4">
      {list.map((item, index) => {
        return (
          <div key={`ChangelogListItem-${index}`}>
            <ChangelogListItem
              key={index}
              date={item.date}
              items={item.items}
            />
            {index !== list.length - 1 ? (
              <Divider key={`divider-${index}`} />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ChangelogList;
