import React from "react";

type SpriteMapProps = {
  missionThreat?: MissionThreatsEnum;
};

const TILE_SIZE = 16;

// StunStrike,
//     MinionHorde,
//     FreezingBlow,
//     BurningGround,
//     PoisonDarts,
//     BladesCyclone,
//     ArmyDamage,
//     TimeLapse

export enum MissionThreatsEnum {
  SUN_STRIKE = 0,
  MINION_HORDE = 1,
  FREEZING_BLOW = 2,
  BURNING_GROUND = 3,
  POISON_DARTS = 4,
  BLADES_CYCLONE = 5,
  ARMY_DAMAGE = 6,
  TIME_LAPSE = 7,
}

type tileMap = {
  tileX: number;
  tileY: number;
};

const missionThreatsMap: Record<MissionThreatsEnum, tileMap> = {
  [MissionThreatsEnum.SUN_STRIKE]: { tileX: 7, tileY: 0 },
  [MissionThreatsEnum.MINION_HORDE]: {
    tileX: 4,
    tileY: 1,
  },
  [MissionThreatsEnum.FREEZING_BLOW]: {
    tileX: 1,
    tileY: 3,
  },
  [MissionThreatsEnum.BURNING_GROUND]: {
    tileX: 3,
    tileY: 0,
  },
  [MissionThreatsEnum.POISON_DARTS]: {
    tileX: 6,
    tileY: 1,
  },
  [MissionThreatsEnum.BLADES_CYCLONE]: {
    tileX: 4,
    tileY: 3,
  },
  [MissionThreatsEnum.ARMY_DAMAGE]: {
    tileX: 2,
    tileY: 3,
  },
  [MissionThreatsEnum.TIME_LAPSE]: {
    tileX: 0,
    tileY: 4,
  },
};

const spriteFile = "./sprites/threats_spritesheet.png";

const ThreatsSpriteMap = ({
  missionThreat = MissionThreatsEnum.SUN_STRIKE,
}: SpriteMapProps) => {
  // console.log("missionThreat", missionThreat);
  return (
    <div
      className="aspect-square"
      style={{
        background: `url(${spriteFile})
        -${missionThreatsMap[missionThreat].tileX * TILE_SIZE}px
        -${missionThreatsMap[missionThreat].tileY * TILE_SIZE}px`,
        width: `${TILE_SIZE}px`,
      }}
    />
  );
};

export default ThreatsSpriteMap;
