import { useContext, useEffect, useState } from "react";
import { Hero } from "../../Models/Hero";
import getInitials from "../../Utils/getInitials";
import HeroesSpriteMap, { heroClassesEnum } from "../Basics/HeroesSpriteMap";
import HeroModal from "./HeroModal";
import useHeroes from "../../Hooks/useHeroes";
import { UserContext } from "../../Providers/UserProvider";
import { Group } from "../../Models/Group";
import { GroupWithInstance } from "../../Models/GroupWithInstance";

export type heroProp = {
  id: number;
  initials: string;
  bg: string;
  heroClassId: heroClassesEnum;
};

type HeroSquareIconProps = {
  hero?: Hero;
  expEarned?: number;
  blockModal?: boolean;
};

const HeroSquareIcon = ({
  hero,
  expEarned,
  blockModal = false,
}: HeroSquareIconProps) => {
  const { isAuthenticated } = useContext(UserContext);
  const { getGroupByHero } = useHeroes(isAuthenticated);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [heroParsed, setHeroParsed] = useState<heroProp>();
  const [group, setGroup] = useState<GroupWithInstance | null>();

  useEffect(() => {
    if (!hero) {
      setHeroParsed(undefined);
      return;
    }

    setGroup(getGroupByHero(hero));

    setHeroParsed({
      id: hero.id,
      initials: getInitials(hero.heroClass.name),
      bg: `bg-scrds-${
        hero.rarity && hero.rarity.color.isNamedColor
          ? hero.rarity.color.name.toLowerCase()
          : "black"
      }`,
      heroClassId: hero.heroClassId,
    });
  }, [getGroupByHero, hero]);

  return (
    <div className={`p-1`}>
      <div
        onClick={() => {
          setIsModalOpen(true);
        }}
        className={`${heroParsed ? heroParsed.bg : ""}
      group
      relative
    border-2
    border-gray-600
    hover:border-gray-900
    hover:cursor-pointer
    aspect-square
    rounded-md
    flex
    justify-center
    items-center
    text-white
    overflow-hidden
    w-14`}
      >
        {expEarned ? (
          <div className="absolute grid top-0 left-0 w-full h-full items-center text-xs text-center font-bold bg-gray-600 bg-opacity-80 text-orange-300 z-10">
            +{expEarned} xp
          </div>
        ) : null}
        {hero && hero.level ? (
          <div className="absolute bottom-0 left-0 bg-gray-600 group-hover:bg-gray-900 text-xs px-1 rounded-tr-md opacity-80">
            Lvl. {hero?.level}
          </div>
        ) : null}
        {hero && hero.level ? (
          <div className={`bg-gray-800 absolute bottom-0 right-0 h-full w-1`}>
            <div
              className="bg-orange-400 text-xs"
              style={{
                height: `${
                  hero.nextExp === 0
                    ? 100
                    : ((hero.experience - hero.previousExp) /
                        (hero.nextExp - hero.previousExp)) *
                      100
                }%`,
              }}
            ></div>
          </div>
        ) : null}
        {hero && heroParsed ? (
          <HeroesSpriteMap heroClass={heroParsed.heroClassId} />
        ) : (
          <div className="w-12 text-center"></div>
        )}
      </div>
      {hero && !blockModal ? (
        <HeroModal
          hero={hero}
          blockEquipUnequip={hero.groupId !== null && group!!! && group.isBusy}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
};

export default HeroSquareIcon;
