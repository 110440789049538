import { useContext } from "react";
import { UserContext } from "../../Providers/UserProvider";
import Page from "../Basics/Page";
import GroupList from "../Group/GroupList";
import useUser from "../../Hooks/useUser";
import { compareHeroRarity, compareHeroExp } from "../../Utils/compareRarity";
import useGroups from "../../Hooks/useGroups";
import CurrencyDisplay from "../Upgrades/CurrencyDisplay";
import useHeroes from "../../Hooks/useHeroes";

const PlayIndex = () => {
  const { isAuthenticated } = useContext(UserContext);
  const { data: user, isFetching } = useUser(isAuthenticated);
  const {
    getQuery: { data: groupsWithInstance, isFetching: isFetchingGroup },
  } = useGroups(isAuthenticated);
  const { isLoading } = useContext(UserContext);
  const {
    getListQuery: { data: heroes, isFetching: isFetchingHeroList },
  } = useHeroes(isAuthenticated);

  if (!user) return <></>;
  return (
    <Page name="Play">
      <div className="w-full flex flex-col">
        <div className="mb-2">
          <CurrencyDisplay />
        </div>

        <GroupList
          heroList={heroes ? heroes : []}
          groups={groupsWithInstance}
        />
      </div>
    </Page>
  );
};

export default PlayIndex;
