import { Hero } from "../../Models/Hero";
import { Item } from "../../Models/Item";
import { Rarity } from "../../Models/Rarity";

const rareRarity: Rarity = {
  id: 1,
  name: "Rare",
  color: {
    r: 0,
    g: 128,
    b: 0,
    a: 255,
    isKnownColor: true,
    isEmpty: false,
    isNamedColor: true,
    isSystemColor: false,
    name: "Green",
  },
  suffixNum: 0,
  prefixNum: 0,
  affixSlotsNum: 0,
};

const commonRarity: Rarity = {
  id: 0,
  name: "Common",
  color: {
    r: 0,
    g: 0,
    b: 255,
    a: 255,
    isKnownColor: true,
    isEmpty: false,
    isNamedColor: true,
    isSystemColor: false,
    name: "Blue",
  },
  suffixNum: 0,
  prefixNum: 0,
  affixSlotsNum: 0,
};
const epicRarity: Rarity = {
  id: 2,
  name: "Epic",
  color: {
    r: 255,
    g: 0,
    b: 255,
    a: 255,
    isKnownColor: true,
    isEmpty: false,
    isNamedColor: true,
    isSystemColor: false,
    name: "Magenta",
  },
  suffixNum: 0,
  prefixNum: 0,
  affixSlotsNum: 0,
};

const legendaryRarity: Rarity = {
  id: 3,
  name: "Legendary",
  color: {
    r: 255,
    g: 165,
    b: 0,
    a: 255,
    isKnownColor: true,
    isEmpty: false,
    isNamedColor: true,
    isSystemColor: false,
    name: "Orange",
  },
  suffixNum: 0,
  prefixNum: 0,
  affixSlotsNum: 0,
};

const mythicRarity: Rarity = {
  id: 4,
  name: "Mythic",
  color: {
    r: 220,
    g: 20,
    b: 0,
    a: 60,
    isKnownColor: true,
    isEmpty: false,
    isNamedColor: true,
    isSystemColor: false,
    name: "Crimson",
  },
  suffixNum: 0,
  prefixNum: 0,
  affixSlotsNum: 0,
};

export const mockedHeroes: Hero[] = [
  {
    id: 15,
    rarityId: 0,
    heroClassId: 3,
    level: 6,
    experience: 12187,
    equippedItems: [],
    groupId: 0,
    rarity: legendaryRarity,
    heroClass: {
      id: 3,
      name: "Wizard",
      classType: {
        id: 2,
        name: "DPS",
      },
    },
    nextExp: 19324,
    previousExp: 12186,
    maxEquipsNumber: 6,
  },
  {
    id: 16,
    rarityId: 0,
    heroClassId: 2,
    level: 6,
    experience: 15325,
    equippedItems: [],
    groupId: 0,
    rarity: epicRarity,
    heroClass: {
      id: 2,
      name: "Ranger",
      classType: {
        id: 2,
        name: "DPS",
      },
    },
    nextExp: 19324,
    previousExp: 12186,
    maxEquipsNumber: 4,
  },
  {
    id: 17,
    rarityId: 0,
    heroClassId: 1,
    level: 6,
    experience: 13809,
    equippedItems: [],
    groupId: 0,
    rarity: rareRarity,
    heroClass: {
      id: 1,
      name: "Knight",
      classType: {
        id: 1,
        name: "Tank",
      },
    },
    nextExp: 19324,
    previousExp: 12186,
    maxEquipsNumber: 2,
  },
  {
    id: 18,
    rarityId: 0,
    heroClassId: 3,
    level: 6,
    experience: 18324,
    equippedItems: [],
    groupId: 0,
    rarity: commonRarity,
    heroClass: {
      id: 3,
      name: "Wizard",
      classType: {
        id: 2,
        name: "DPS",
      },
    },
    nextExp: 19324,
    previousExp: 12186,
    maxEquipsNumber: 1,
  },
];

export const mockedItems: Item[] = [
  {
    id: 2,
    name: "RandomizeRarityOrb",
    iLevel: 0,
    rarityId: 0,
    affixesJson: "",
    implicitJson: "",
    stackable: true,
    stack: 0,
    isEquipped: false,
    itemTypeId: 2,
    rarity: commonRarity,
    equippedByClass: [],
  },
  {
    id: 0,
    name: "SuperCommonOrb",
    iLevel: 0,
    rarityId: 0,
    affixesJson: "",
    implicitJson: "",
    stackable: true,
    stack: 0,
    isEquipped: false,
    itemTypeId: 0,
    rarity: commonRarity,
    equippedByClass: [],
  },
  {
    id: 1,
    name: "CommonizerOrb",
    iLevel: 0,
    rarityId: 1,
    affixesJson: "",
    implicitJson: "",
    stackable: true,
    stack: 0,
    isEquipped: false,
    itemTypeId: 1,
    rarity: rareRarity,
    equippedByClass: [],
  },
  {
    id: 4,
    name: "ChaoticOrb",
    iLevel: 0,
    rarityId: 2,
    affixesJson: "",
    implicitJson: "",
    stackable: true,
    stack: 0,
    isEquipped: false,
    itemTypeId: 4,
    rarity: epicRarity,
    equippedByClass: [],
  },
  {
    id: 3,
    name: "EpicOrb",
    iLevel: 0,
    rarityId: 3,
    affixesJson: "",
    implicitJson: "",
    stackable: true,
    stack: 0,
    isEquipped: false,
    itemTypeId: 3,
    rarity: legendaryRarity,
    equippedByClass: [],
  },
  {
    id: 5,
    name: "UnrealOrb",
    iLevel: 0,
    rarityId: 3,
    affixesJson: "",
    implicitJson: "",
    stackable: true,
    stack: 0,
    isEquipped: false,
    itemTypeId: 5,
    rarity: mythicRarity,
    equippedByClass: [],
  },
];
