import { Item } from "../../Models/Item";
import ItemIcon from "../Item/ItemIcon";

type StartPortalMissionItemProps = {
  item: Item;
};

const StartPortalMissionItem = ({ item }: StartPortalMissionItemProps) => {
  if (!item) return <></>;
  return (
    <div>
      <ItemIcon
        key={`start-porta-item-${0}-${item ? item.id : "0"}`}
        item={item}
        index={0}
      />
    </div>
  );
};

export default StartPortalMissionItem;
