import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Providers/UserProvider";
import HeroSquareIcon from "./HeroSquareIcon";
import useUser from "../../Hooks/useUser";
import { Hero } from "../../Models/Hero";

type HeroGridProps = {
  heroes: Array<Hero>;
  gridSize?: number;
};

const HeroGrid = ({ gridSize = 100, heroes }: HeroGridProps) => {
  const { isAuthenticated } = useContext(UserContext);
  const { data: user, isFetching } = useUser(isAuthenticated);
  const [grid, setGrid] = useState<Array<number>>([]);

  const completedGridSize = gridSize + (10 - (gridSize % 10));

  useEffect(() => {
    const ar = [];
    for (let i = 0; i < completedGridSize; i += 1) {
      ar.push(i);
    }
    setGrid(ar);
  }, [completedGridSize, heroes]);

  if (!user) return <></>;

  return (
    <div
      className={`w-full shadow-xl grid grid-cols-5 bg-gray-700/50 border-2 border-transparent hover:border-gray-900 p-1 rounded-md`}
    >
      {grid.map((i) => {
        const hero = heroes[i];
        return (
          <HeroSquareIcon
            key={`itemicongrid-${i}-${hero ? hero.id : "0"}`}
            hero={hero}
          />
        );
      })}
    </div>
  );
};

export default HeroGrid;
