import React from "react";
import Confetti, { ConfettiConfig } from "react-dom-confetti";
import { CSSTransition } from "react-transition-group";
import { Rarity, convertRarityColorToHex } from "../../Models/Rarity";

type CongratulationsProps = {
  show: boolean;
  rarity?: Rarity;
};

const defaultColors = ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"];

const Congratulations = ({ show, rarity }: CongratulationsProps) => {
  const config: ConfettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    width: "10px",
    height: "10px",
    colors:
      rarity && rarity.color
        ? [convertRarityColorToHex(rarity.color)]
        : defaultColors,
  };

  return (
    <>
      {/* <CSSTransition
        in={show}
        timeout={1000}
        classNames={{
          enter: "opacity-0",
          enterActive: "animate-fadeIn",
          exit: "opacity-100",
          exitActive: "animate-fadeOut",
        }}
        unmountOnExit
      >
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <h2 className="text-4xl font-bold text-green-500 shadow-text">
            Congratulations!
          </h2>
        </div>
      </CSSTransition> */}
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
        <Confetti active={show} config={config} />
      </div>
    </>
  );
};

export default Congratulations;
