import React, { useRef, useEffect } from 'react';

type CanvasProps = {
  draw: (ctx: CanvasRenderingContext2D, frameCount: number) => void;
  canWidth?: number;
  canHeight?: number;
};

const Canvas = React.memo(({ draw, canHeight = 1000, canWidth = 1000 }: CanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;
    let frameCount = 0;
    let animationFrameId: number;

    const render = () => {
      frameCount += 1;
      // console.log('frameCount', frameCount);
      draw(ctx, frameCount);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return <canvas ref={canvasRef} width={`${canWidth}px`} height={`${canHeight}px`} />;
});

export default Canvas;
