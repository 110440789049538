import { useQuery } from "react-query";
import axios from "axios";
import { PublicUserDTO } from "../Models/PublicUserDTO";

async function getExpRanking(): Promise<PublicUserDTO[]> {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/Ranking/GetExpRanking`
  );
  return res.data;
}

export default function useRanking() {
  const getExpRankingQuery = useQuery(["getExpRanking"], getExpRanking, {
    refetchOnWindowFocus: false,
  });

  return { getExpRankingQuery };
}
