import React, { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../Providers/UserProvider";
import Page from "../Basics/Page";
import ItemGrid from "./ItemGrid";
import useUser from "../../Hooks/useUser";
import { Item } from "../../Models/Item";
import useItems from "../../Hooks/useItems";
import { compareItemRarity } from "../../Utils/compareRarity";
import LoadingButton from "../Basics/LoadingButton";
import CurrencyDisplay from "../Upgrades/CurrencyDisplay";

const ItemIndex = () => {
  const { isLoading, isAuthenticated } = useContext(UserContext);
  const { data: user, isFetching } = useUser(isAuthenticated);
  const [sortedItems, setSortedItems] = useState<Array<Item>>([]);
  const {
    add: addItem,
    scrapAllCommon,
    scrapAllRare,
    scrapAllEpic,
    getQuery: { data: itemsList, isFetching: isFetchingItemsList },
  } = useItems(isAuthenticated);

  useEffect(() => {
    if (!itemsList) return;
    setSortedItems(
      itemsList
        .filter(
          (item) => !item.stackable || (item.stackable && item.stack! > 0)
        )
        .sort(compareItemRarity)
    );
  }, [itemsList]);

  const onClickScrapCommon = useCallback(() => {
    if (!user) return;
    scrapAllCommon.mutate();
  }, [user, scrapAllCommon]);

  const onClickScrapRare = useCallback(() => {
    if (!user) return;
    scrapAllRare.mutate();
  }, [user, scrapAllRare]);

  const onClickScrapEpic = useCallback(() => {
    if (!user) return;
    scrapAllEpic.mutate();
  }, [user, scrapAllEpic]);

  console.log("sortedItems", sortedItems);

  if (!user) return <></>;
  return (
    <Page name="Item" backgroundSrc="./sprites/storage.jpg">
      {/* {isLoading || isFetching ? <span className="ml-2">Loading</span> : null} */}
      <div className="w-full flex flex-col">
        <div className="mb-2">
          <CurrencyDisplay />
        </div>
        <div className="w-full pb-2 flex">
          <span className="text-xs">
            <LoadingButton
              className="border-scrds-blue"
              text="Scrap Common"
              isLoading={scrapAllCommon.isLoading}
              onClick={onClickScrapCommon}
            />
          </span>
          <span className="pl-2 text-xs">
            <LoadingButton
              className="border-scrds-green"
              text="Scrap Rare"
              isLoading={scrapAllRare.isLoading}
              onClick={onClickScrapRare}
            />
          </span>
          <span className="pl-2 text-xs">
            <LoadingButton
              className="border-scrds-magenta"
              text="Scrap Epic"
              isLoading={scrapAllEpic.isLoading}
              onClick={onClickScrapEpic}
            />
          </span>
        </div>
        <ItemGrid items={sortedItems} userId={user.id} />
      </div>
    </Page>
  );
};

export default ItemIndex;
