import React, { useEffect, useRef, useState } from "react";
import { Item } from "../../Models/Item";
import getInitials from "../../Utils/getInitials";
import ItemModal from "./ItemModal";
import CurrenciesSpriteMap, {
  currenciesTypesEnum,
} from "../Basics/CurrenciesSpriteMap";
import InventoryIcon from "../SVGIcons/InventoryIcon";
import LockIcon from "../SVGIcons/LockIcon";

export type ItemIconSize = "small" | "normal";

const getIconWidth = (itemIconSize?: ItemIconSize) => {
  switch (itemIconSize) {
    case "small":
      return "w-11";
    case "normal":
      return "w-14";
    case null:
      return "w-14";
    default:
      return "w-14";
  }
};

export type itemProp = {
  id: number;
  initials: string;
  bg: string;
  currencyType: currenciesTypesEnum;
};

type ItemIconProps = {
  disableWhenZeroStack?: boolean;
  allowModal?: boolean;
  item?: Item;
  index: number;
  isItemSlotLocked?: boolean;
  itemIconSize?: ItemIconSize;
};

export const equipItemIconDragType = "equipItemIconDragType";
export const unequipItemIconDragType = "unequipItemIconDragType";

const getSlotRarity = (index: number) => {
  if (index === 1) return <span className="text-scrds-green">Rare</span>;
  if (index === 2 || index === 3)
    return <span className="text-scrds-magenta/75">Epic</span>;
  if (index === 4 || index === 5)
    return <span className="text-scrds-orange/75">Legendary</span>;
  if (index === 6 || index === 7)
    return <span className="text-scrds-crimson">Mythic</span>;
  if (index === 8 || index === 9)
    return <span className="text-scrds-gold/75">Ascended</span>;

  return <span className="text-scrds-green">Rare</span>;
};

const ItemIcon = ({
  disableWhenZeroStack = false,
  allowModal = false,
  item,
  index,
  isItemSlotLocked,
  itemIconSize = "normal",
}: ItemIconProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const ref = useRef(null);
  const [itemParsed, setItemParsed] = useState<itemProp | undefined>();

  useEffect(() => {
    if (!item) {
      setItemParsed(undefined);
      return;
    }

    const currencyType = currenciesTypesEnum[item.itemTypeId];

    setItemParsed({
      id: item.id,
      initials: getInitials(item.name),
      currencyType: currencyType ? item.itemTypeId : -1,
      bg: `bg-scrds-${
        item.rarity && item.rarity.color.isNamedColor
          ? item.rarity.color.name.toLowerCase()
          : "black"
      }`,
    });
  }, [item]);

  return (
    <div
      className="p-1"
      ref={ref}
      onClick={() => {
        if (allowModal) setIsModalOpen(true);
      }}
    >
      <div
        className={`relative ${itemParsed ? itemParsed.bg : "bg-gray-900"} ${
          item && item.isEquipped ? "border-gray-900" : "border-gray-600"
        } border-2
        hover:cursor-pointer
        group
        aspect-square
        rounded-md
        flex
        relative
        justify-center
        items-center
        text-white
        ${getIconWidth(itemIconSize)}`}
      >
        {isItemSlotLocked ? (
          <div className="flex flex-col items-center text-center text-gray-600">
            <LockIcon className="h-5 w-5" fill="#4b5563" />
            <span className="block text-inIcon">
              Only {getSlotRarity(index)}
            </span>
          </div>
        ) : null}
        {item && item.stackable && item.stack! > 0 ? (
          <div className="absolute top-0 left-0 overflow-hidden text-xs left-1">
            {item.stack}
          </div>
        ) : null}
        {item && item.iLevel > 0 ? (
          <div className="absolute bottom-0 left-0 bg-gray-600 group-hover:bg-gray-900 text-xs px-1 rounded-tr-md opacity-80">
            Lvl. {item?.iLevel}
          </div>
        ) : null}
        {item && itemParsed ? (
          itemParsed.currencyType >= 0 ? (
            <CurrenciesSpriteMap
              currencyId={itemParsed.currencyType}
              imageOpacity={disableWhenZeroStack ? 0.5 : 1}
              iconSize={itemIconSize}
            />
          ) : (
            itemParsed.initials
          )
        ) : (
          <div />
        )}

        {item && itemParsed ? (
          <ItemModal
            item={item}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ItemIcon;
